export const getStateAbbrFromStateName = (stateName: string) => {
  switch (stateName) {
    case "New York":
      return "NY";
    case "Massachusetts":
      return "MA";
    case "TxPPC":
      return "TX";
    case "CPS":
      return "CPS";
    case "NBU":
      return "NBU";
    case "LCRA":
      return "LCRA";
    case "Austin Energy":
      return "AE";
    case "First Energy":
      return "FIRST_ENERGY";
    case "Socal Edison":
      return "SOCAL";
    case "NiSource":
      return "NISOURCE";
    case "Dubai":
      return "DUBAI";
    case "Utah":
      return "UTAH";
    case "Rhode Island":
      return "RI";
    case "PSE&G":
      return "PSE&G";
    case "Vermont":
      return "VT";
    default:
      // NGRID only requires two states.
      // This will have the API request a non-existent state
      return "N/A";
  }
};

export const getStatesConfig = (stateAbbr: string) => {
  const result = {
    location: stateAbbr.toLowerCase(),
    isTxPPC: false,
    isFirstEnergy: false,
    isSoCal: false,
    isNiSource: false,
    isDubai: false,
    isUtah: false,
    isRhodeIsland: false,
    isPseng: false,
    isVermont: false,
    isNextGenCompany: false,
    isFeederDisable: false,
    isDemoRegridDepot: false,
  };

  switch (stateAbbr.toLowerCase()) {
    case "txppc":
    case "tx":
    case "cps":
    case "nbu":
    case "lcra":
    case "austin energy":
    case "ae":
      result.isTxPPC = true;
      result.isNextGenCompany = true;
      result.isFeederDisable = true;
      break;
    case "first energy":
    case "first_energy":
      result.isFirstEnergy = true;
      result.isNextGenCompany = true;
      break;
    case "socal":
    case "socal edison":
      result.isSoCal = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "nisource":
      result.isNiSource = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "dubai":
      result.isDubai = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "utah":
      result.isUtah = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "ri":
    case "rhode island":
      result.isRhodeIsland = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "pse&g":
      result.isPseng = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "vermont":
    case "vt":
      result.isVermont = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    default:
      break;
  }

  return result;
};
