import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

type LayerControlsDialogProps = {
  open: boolean;
  onClose: () => void;
  props: any;
  activeLayers: string[];
  handleLayerChange: (layer: string) => () => void;
};

export default function LayerControlsDialog({
  open,
  onClose,
  props,
  activeLayers,
  handleLayerChange,
}: LayerControlsDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box className={"layer-controls"}>
          <Stack spacing={1} className={"layer-controls"}>
            <Typography variant="h3">Layer Controls</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("sites")}
                  onChange={handleLayerChange("sites")}
                  name="block-groups"
                />
              }
              label="Sites"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("charging-stations")}
                  onChange={handleLayerChange("charging-stations")}
                  name="block-groups"
                />
              }
              label="Charging Stations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("poverty")}
                  onChange={handleLayerChange("poverty")}
                  name="block-groups"
                />
              }
              label="Poverty"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("non-white")}
                  onChange={handleLayerChange("non-white")}
                  name="block-groups"
                />
              }
              label="Non-White"
            />
            {!props.isTxPPC && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeLayers.includes("mfh")}
                    onChange={handleLayerChange("mfh")}
                    name="block-groups"
                  />
                }
                label="Multifamily Housing"
              />
            )}
            {props.location?.name === "Austin" && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeLayers.includes("austin-energy")}
                      onChange={handleLayerChange("austin-energy")}
                      name="block-groups"
                    />
                  }
                  label="Austin Energy Service Territory"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeLayers.includes(
                        "bluebonnet-electric-cooperative"
                      )}
                      onChange={handleLayerChange(
                        "bluebonnet-electric-cooperative"
                      )}
                      name="block-groups"
                    />
                  }
                  label="Bluebonnet Electric Cooperative Service Territory"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeLayers.includes("school-districts")}
                      onChange={handleLayerChange("school-districts")}
                      name="block-groups"
                    />
                  }
                  label="School Districts"
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("justice-40")}
                  onChange={handleLayerChange("justice-40")}
                  name="block-groups"
                />
              }
              label="Justice 40"
            />
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
