import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FleetScenario } from "types/fleets";

export type AssumptionsPropertyTableProps = {
  scenario?: FleetScenario;
};

export default function AssumptionsPropertyTable({
  scenario,
}: AssumptionsPropertyTableProps) {
  // Temporarily removed LifeCycle and Energy Consumption lines as we didn't get it from the user.
  const equipmentLifecycleStr = "12 years";
  // scenario !== undefined ? `${scenario?.equipmentLifecycleYears} years` : "";

  return (
    <Stack spacing={2}>
      <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
        Assumptions
      </Typography>
      <List>
        <ListItem disableGutters>
          <ListItemText
            primary="Equipment Lifecycle"
            secondary={equipmentLifecycleStr}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Diesel Cost"
            secondary={
              scenario?.fuel_cost ? `$${scenario?.fuel_cost} per gallon` : ""
            }
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Workdays"
            secondary={"365 workdays per year"}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Maintenance Downtime % for Diesel Vehicles"
            secondary={scenario?.annual_ice_maintenance}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Maintenance Downtime % for Electric Vehicles"
            secondary={scenario?.annual_ev_maintenance}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Driver Salary"
            secondary={"$80,000 per driver per year"}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Vehicle Insurance"
            secondary={"3% of purchase cost per vehicle per year"}
          />
        </ListItem>
      </List>
    </Stack>
  );
}
