import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import { loadDemand } from "api/demand.js";
import EvPenetration from "components/EvPenetration";
import HelpTooltip from "components/HelpTooltip";
import TrafficModelControl from "dashboard/controls/trafficModelControl";
import Legend from "dashboard/depots/Legend";
import Widget from "dashboard/depots/Widget";
import WidgetContainer from "dashboard/depots/WidgetContainer";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import { BBox, Feature, FeatureCollection } from "geojson";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Map, {
  Anchor,
  Layer,
  LayerProps,
  MapRef,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import { Ac } from "types/ac";
import { ChargingDemandSimulation } from "types/charging-demand-simulation";
import {
  GrowthScenario,
  getLightDutyVehicleClass,
} from "types/growth-scenario";
import { Location } from "types/location";
import { TrafficModel } from "types/traffic-model";
import useSupercluster from "use-supercluster";
import { range } from "utils/array";
import { scaleDemandByGrowthScenarioYear } from "utils/demand";
import { getStateAbbrFromStateName } from "utils/state-abbr";
import AccessControl from "../AccessControl";
import {
  BlockGroupPopupContent,
  ChargingStationPopupContent,
  DemographicsPopupContent,
  GenericGeoJsonFeaturePopupContent,
  SubstationPopupContent,
} from "../PopupContent.jsx";
import DemandHourControl from "../controls/DemandHourControl";
import DemandTypeControl from "../controls/DemandTypeControl";
import DownloadButton from "../controls/DownloadButton";
import GrowthScenarioControl from "../controls/GrowthScenarioControl";
import SeasonControl from "../controls/SeasonControl";
import StickyBottomBox from "../controls/StickyBottomBox";
import YearControl from "../controls/YearControl";
import HomeButton from "../depots/HomeButton";
import GeocoderControl from "../depots/geocoder-control";
import DemandDensityControl from "./DemandDensityControl";
import DemographicsLegend from "./DemographicsLegend";
import { downloadDemand } from "./downloadDemand";
import { useAccessToken } from "utils/get-access-token";

const EXCLUSIVE_EV_NETWORKS = [
  "Tesla",
  "Tesla Destination",
  "RIVIAN_ADVENTURE",
];

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const ACS_METRIC_POVERTY = 2;
const ACS_METRIC_NONWHITE = 3;
const ACS_METRIC_MULTIFAMILY_HOUSING = 4;

type PopupInfo = {
  feature: Feature;
  x: number;
  y: number;
  lng: number;
  lat: number;
};

export type DemandPageProps = {
  location?: Location;
  ac?: Ac;
  onAcChange?: (newAc: Ac) => void;
  year?: number;
  onYearChange?: (event: Event) => void;
  growthScenarios?: GrowthScenario[];
  selectedGrowthScenario?: GrowthScenario;
  onGrowthScenarioChange?: (event: Event) => void;
  selectedTrafficModelId?: number;
  selectedTrafficModel?: TrafficModel;
  chargingDemandSimulations?: ChargingDemandSimulation[];
  blockGroups?: FeatureCollection;
  demographics?: any;
  chargingStations?: any;
  substations?: any;
  selectedChargingDemandSimulation?: any;
  loading?: boolean;
  trafficModels?: TrafficModel[];
  setSelectedTrafficModelId?: (id: number) => void;
  setSelectedGrowthScenarioId?: (id: number | undefined) => void;
};

function DemandPage(props: DemandPageProps) {
  const { getToken } = useAccessToken();
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const mapRef = useRef<MapRef>(null);
  // Map popup anchor position
  const [anchor, setAnchor] = useState<Anchor>();
  const [cursor, setCursor] = useState<string>("auto");
  const [viewport, setViewport] = useState({
    latitude: props.location?.center[0],
    longitude: props.location?.center[1],
    zoom: props.location?.zoom,
  });
  const [displayMode, setDisplayMode] = useState<"light" | "dark">("dark");
  const [loading, setLoading] = useState(false);
  const [activeLayers, setActiveLayers] = useState<
    (
      | "block-groups"
      | "block-groups-outline"
      | "substations"
      | "charging-stations"
      | "poverty"
      | "non-white"
      | "mfh"
    )[]
  >(["block-groups", "block-groups-outline"]);
  const [popupInfo, setPopupInfo] = useState<PopupInfo | undefined>(undefined);
  const [markerType, setMarkerType] = useState<string>("");

  const [demand, setDemand] = useState({});
  const [allDayDemand, setAllDayDemand] = useState({});
  const [loadingDemand, setLoadingDemand] = useState(false);
  const [loadingAllDayDemand, setLoadingAllDayDemand] = useState(false);

  const [hourlyDemandData, setHourlyDemandData] = useState<Object[]>([]);
  const [preloading, setPreloading] = useState(false);

  // CDS sub-selectors
  const [hour, setHour] = useState(12);
  const [demandTypes, setDemandTypes] = useState(["Home"]);

  // display
  const [useDemandDensity, setUseDemandDensity] = useState(true);
  const [allDayCheckbox, setAllDayCheckbox] = useState(false);

  // animation
  const animationIntervalRef = useRef<number | null>(null);
  const [playingAnimation, setPlayingAnimation] = useState(false);

  // download data
  const [downloading, setDownloading] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(false);
  const [demandInfo, setDemandInfo] = useState({});

  const lightDutyVehicleClass =
    props.selectedGrowthScenario !== undefined
      ? getLightDutyVehicleClass(props.selectedGrowthScenario)
      : undefined;

  const currentYearVehicleClassData =
    lightDutyVehicleClass?.annualData !== undefined
      ? lightDutyVehicleClass?.annualData?.find(
          (yearData) => yearData.year === props.year
        )
      : undefined;

  useDebouncedEffect(
    () => {
      // don't attempt to load data if any dependencies are not yet defined,
      // as this side effect relies on other side effects loading data into
      // dependencies
      if (
        [
          props.location,
          props.ac,
          demandTypes,
          hour,
          props.selectedChargingDemandSimulation,
        ].some((value) => value === undefined)
      ) {
        return;
      }

      // don't attempt to update data if we're playing an animation, or else
      // each change of the animation hour will cause an update
      if (playingAnimation) {
        return;
      }

      async function loadData() {
        const apiToken = await getToken();
        const hourParam = allDayCheckbox ? "all" : hour; // override hour if all day checkbox is checked
        setLoadingDemand(true);
        setLoadingAllDayDemand(true);

        try {
          const [demand, allDayDemand] = await Promise.all([
            loadDemand(
              props.selectedChargingDemandSimulation.id,
              demandTypes,
              hourParam,
              props.location?.id,
              apiToken
            ),
            loadDemand(
              props.selectedChargingDemandSimulation.id,
              demandTypes,
              "all",
              props.location?.id,
              apiToken
            ),
          ]);

          setDemand(demand);
          setAllDayDemand(allDayDemand);
        } catch (error) {
          console.error("Error loading demand data", error);
        } finally {
          setLoadingDemand(false);
          setLoadingAllDayDemand(false);
        }

        setLoadingDemand(false);
        setLoadingAllDayDemand(false);
      }
      loadData();
    },
    [
      props.location,
      props.ac,
      demandTypes,
      hour,
      props.selectedChargingDemandSimulation,
      allDayCheckbox,
      props.blockGroups,
    ],
    1000
  );

  useEffect(() => {
    // when props.location changes, recenter on new location
    setPopupInfo(undefined);
    // reset active layers to default when location changes
    setActiveLayers(["block-groups", "block-groups-outline"]);
    setViewport({
      latitude: props.location?.center[0],
      longitude: props.location?.center[1],
      zoom: props.location?.zoom,
    });
  }, [props.location]);

  const bounds = mapRef.current
    ? (mapRef.current.getMap().getBounds().toArray().flat() as BBox)
    : undefined;

  var { clusters, supercluster } = useSupercluster({
    points: props.chargingStations?.features || [],
    bounds,
    zoom: viewport.zoom || 9,
    options: { radius: 50, maxZoom: 13 },
  });

  const handleDisplayModeChange = () => {
    setLoading(true);
    setDisplayMode(displayMode === "light" ? "dark" : "light");
    setLoading(false);
  };

  const handleTrafficModelChange = (event: SelectChangeEvent) => {
    setPopupInfo(undefined);
    setLoadingDemand(true);
    if (props.trafficModels === undefined) return;
    const selectedTrafficModel = props.trafficModels.find(
      (tm) => tm.id === Number((event.target as HTMLInputElement).value)
    );
    if (selectedTrafficModel !== undefined) {
      props.setSelectedTrafficModelId?.(selectedTrafficModel.id);
    }
    setLoadingDemand(false);
  };

  const handleDemandTypesChange = (event: Event) => {
    const { value, checked } = event.target as HTMLInputElement;
    if (checked) {
      let newDemandTypes = [...demandTypes];
      newDemandTypes.push(value);
      setDemandTypes(newDemandTypes);
    } else {
      let newDemandTypes = demandTypes.filter((e) => e !== value);
      setDemandTypes(newDemandTypes);
    }
  };

  const handleHourChange = (hour: number) => {
    if (playingAnimation) stopAnimating();
    setHour(hour);
  };

  const handleUseDemandDensityChange = (event: Event, value: boolean) => {
    setUseDemandDensity(value);
  };

  const handleAllDayCheckboxChange = (checked: boolean) => {
    if (checked) stopAnimating();
    setAllDayCheckbox(checked);
  };

  // creating a geojson for demographics data
  // based on the centroid of the block group as the coordinates of a circle
  const demographicsGeoJson: any = useMemo(() => {
    if (props.demographics !== undefined) {
      let features = [];
      for (const [geoid, demographic] of Object.entries(props.demographics)) {
        const blockGroup = props.blockGroups?.features.find(
          (feature) => feature.properties?.geoid === geoid
        );
        if (blockGroup) {
          const centroid = blockGroup.properties?.centroid;
          const feature = {
            type: "Feature",
            properties: {
              geoid,
              demographic,
            },
            geometry: {
              type: "Point",
              coordinates: centroid.coordinates,
            },
          };
          features.push(feature);
        }
      }
      return {
        type: "FeatureCollection",
        features,
      };
    }
    return undefined;
  }, [props.demographics, props.blockGroups]);

  /* Animation Stuff */
  const handleAnimationButtonClick = (event: Event) => {
    playingAnimation ? stopAnimating() : startAnimating();
  };

  const startAnimating = async () => {
    setPreloading(true);
    setHour(0);
    let hourlyFetchPromises = [];
    const apiToken = await getToken();
    for (let hour = 0; hour < 24; hour++) {
      hourlyFetchPromises.push(
        loadDemand(
          props.selectedChargingDemandSimulation.id,
          demandTypes,
          hour,
          props.location?.id,
          apiToken
        )
      );
    }
    let hourlyDemandData = await Promise.all(hourlyFetchPromises);
    setHourlyDemandData(hourlyDemandData);
    // immediately update hour, and then also set up a timer for subsequent changes
    setPlayingAnimation(true);
    setPreloading(false);
  };

  useEffect(() => {
    if (playingAnimation) {
      const updateNextHour = async () => {
        setHour((prevHour) => (prevHour + 1) % 24);
        // there is a useEffect for when hour changes that updates the
        // hourlyDemandData. This is because the previous hour was not
        // available to that state update then, and new hour states are
        // not available via hour here.
        // see: https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval
      };
      let animationInterval = window.setInterval(updateNextHour, 1000);
      animationIntervalRef.current = animationInterval;
    } else {
      window.clearInterval(animationIntervalRef.current ?? undefined);
      animationIntervalRef.current = null;
    }
  }, [playingAnimation]);

  // stupid hacky way of handling updating hourly demand with intervals above
  useEffect(() => {
    if (playingAnimation) {
      setDemand(hourlyDemandData[hour]);
      if (hour === 23) stopAnimating(); // CORE-702 temporary fix
    }
  }, [hour, hourlyDemandData, playingAnimation]);

  const stopAnimating = () => {
    setPlayingAnimation(false);
  };

  // Leaflet is hard to render and freezes the application. This is a separate state that is set on a delay so that
  // the entire application doesn't freeze when the year slider is being changed
  const [scaleYear, setScaleYear] = useState(props.year);
  useDebouncedEffect(
    () => {
      setScaleYear(props.year);
    },
    [props.year],
    1000
  );

  const scaledDemand = useMemo(() => {
    if (
      lightDutyVehicleClass?.annualData !== undefined &&
      scaleYear !== undefined
    ) {
      const allDayDemandSum = Number(
        Object.values(allDayDemand).reduce(
          (sum, val) => Number(sum) + Number(val),
          0
        )
      );
      const scaledDemandData = scaleDemandByGrowthScenarioYear(
        lightDutyVehicleClass.annualData,
        scaleYear,
        demand,
        allDayDemandSum,
        demandTypes
      );
      setDemandInfo(scaledDemandData);
      return scaledDemandData;
    }
    return {};
  }, [lightDutyVehicleClass, scaleYear, demand, allDayDemand, demandTypes]);

  const onClick = useCallback(
    (event: mapboxgl.MapLayerMouseEvent) => {
      const {
        features,
        point: { x, y },
        lngLat: { lng, lat },
      } = event;
      const hoveredFeature = features && features[0];
      if (
        hoveredFeature?.layer.id === "clusters" &&
        supercluster !== undefined
      ) {
        const expansionZoom = Math.min(
          supercluster.getClusterExpansionZoom(
            hoveredFeature.properties?.cluster_id
          ),
          14
        );
        mapRef.current?.getMap().easeTo({
          center: [lng, lat],
          zoom: expansionZoom,
        });
      } else {
        setMarkerType(hoveredFeature?.layer.id || "");
        let position: Anchor = "top";
        if (y > 400) {
          position = "bottom";
        }
        setAnchor(position);
        setPopupInfo(
          hoveredFeature && { feature: hoveredFeature, x, y, lng, lat }
        );
      }
    },
    [supercluster]
  );

  const handleRecenterClick = () => {
    setViewport({
      latitude: props.location?.center[0],
      longitude: props.location?.center[1],
      zoom: props.location?.zoom,
    });
  };

  const colors: (string | number)[] = [
    "#FFF1E8",
    1,
    "#FFC69B",
    10,
    "#FF9F4D",
    100,
    "#FF7C23",
    1000,
    "#A14800",
    10000,
    "#421B00",
  ];

  const chargingDemandLegend: (string | number)[] = [
    "Less than 1",
    "#FFF1E8",
    "1 to 10",
    "#FFC69B",
    "10 to 100",
    "#FF9F4D",
    "100 to 1000",
    "#FF7C23",
    "1000 to 10000",
    "#A14800",
    "Greater than 10000",
    "#421B00",
  ];

  const chargingStationsLegend: string[] = [
    "Public",
    "#05C2cc",
    "Private",
    "#de6b48",
    "Exclusive",
    "#67d22d",
  ];

  const povertyLegend: (string | number)[] = [
    "25",
    "#0000ff",
    "50",
    "#0000ff",
    "75",
    "#0000ff",
    "100",
    "#0000ff",
  ];

  const nonWhiteLegend: (string | number)[] = [
    "25",
    "#ffeb7f",
    "50",
    "#ffeb7f",
    "75",
    "#ffeb7f",
    "100",
    "#ffeb7f",
  ];

  const mfhLegend: (string | number)[] = [
    "25",
    "#ff0000",
    "50",
    "#ff0000",
    "75",
    "#ff0000",
    "100",
    "#ff0000",
  ];

  const circleSizes: number[] = [3, 6, 9, 12];

  const blockGroupLayerStyle: LayerProps = {
    id: "block-groups",
    type: "fill",
    paint: {
      "fill-color": [
        "step",
        useDemandDensity
          ? [
              "/",
              ["get", ["get", "geoid"], ["literal", scaledDemand]],
              ["*", ["to-number", ["get", "area"]], 3.86102e-7],
            ]
          : ["get", ["get", "geoid"], ["literal", scaledDemand]],
        ...colors,
      ],
      "fill-opacity": 0.6,
      "fill-outline-color": "#000000",
    },
  };

  const blockGroupOutlineLayerStyle: LayerProps = {
    id: "block-groups-outline",
    type: "line",
    paint: {
      "line-color": "#000000",
      "line-width": 1,
    },
  };

  const substationsLayerStyle: LayerProps = {
    id: "substations",
    type: "circle",
    paint: {
      "circle-color": "#458f28",
      "circle-radius": 5,
    },
  };

  const chargingStationsLayerStyle: LayerProps = {
    id: "charging-stations",
    type: "circle",
    paint: {
      "circle-color": [
        "case",
        ["in", ["get", "evNetwork"], ["literal", EXCLUSIVE_EV_NETWORKS]],
        "#67d22d",
        ["==", ["get", "access"], "public"],
        "#05C2cc",
        ["==", ["get", "access"], "private"],
        "#de6b48",
        "#c0c0c0",
      ],
      "circle-radius": 5,
    },
  };

  const clustersLayerStyle: LayerProps = {
    id: "clusters",
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#691c63",
      "circle-radius": 18,
      "circle-stroke-width": 5,
      "circle-stroke-color": "#691c63",
      "circle-stroke-opacity": 0.5,
    },
  };

  const clusterCountLayerStyle: LayerProps = {
    id: "cluster-count",
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-size": 12,
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    },
    paint: {
      "text-color": "#ffffff",
    },
  };

  const unclusteredPointLayerStyle: LayerProps = {
    id: "unclustered-point",
    type: "circle",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": [
        "case",
        ["in", ["get", "evNetwork"], ["literal", EXCLUSIVE_EV_NETWORKS]],
        "#67d22d",
        ["==", ["get", "access"], "public"],
        "#05C2cc",
        ["==", ["get", "access"], "private"],
        "#de6b48",
        "#c0c0c0",
      ],
      "circle-radius": 5,
    },
  };

  const povertyLayerStyle: LayerProps = {
    id: "poverty",
    type: "circle",
    paint: {
      "circle-color": "#0000ff",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const nonWhiteLayerStyle: LayerProps = {
    id: "non-white",
    type: "circle",
    paint: {
      "circle-color": "#ffeb7f",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const mfhLayerStyle: LayerProps = {
    id: "mfh",
    type: "circle",
    paint: {
      "circle-color": "#ff0000",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  const handleLayerChange =
    (
      layer:
        | "substations"
        | "charging-stations"
        | "poverty"
        | "non-white"
        | "mfh"
    ) =>
    () => {
      // if box is checked, layer is added to active-layers
      // if box is unchecked, layer is removed from active-layers
      if (activeLayers.includes(layer)) {
        setActiveLayers(activeLayers.filter((l) => l !== layer));
        if (markerType === layer || markerType === "unclustered-point") {
          setPopupInfo(undefined);
          setMarkerType("");
        }
      } else {
        setActiveLayers([...activeLayers, layer]);
      }
    };

  /* Data Download */

  const handleDownloadButtonClick = async () => {
    if (currentYearVehicleClassData !== undefined) {
      setDownloading(true);
      const apiToken = await getToken();
      const downloadSuccess = await downloadDemand(
        demandInfo,
        props,
        lightDutyVehicleClass?.annualData,
        scaleYear,
        demandTypes,
        props.selectedGrowthScenario?.name,
        apiToken
      );
      setErrorDownloading(!downloadSuccess);
      setDownloading(false);
    }
  };

  const tourSteps = [
    {
      selector: "#map",
      content: (
        <Typography>
          The Charging Demand page shows where charging demand is geographically
          located.
        </Typography>
      ),
    },
    {
      selector: ".vehicle-population-controls",
      content: (
        <Typography>
          Vehicle population controls allow you to adjust how the vehicle
          population will grow and view the projected demand data for a given
          year. New growth scenarios can be created via the Timeline tab.
        </Typography>
      ),
    },
    {
      selector: ".demand-controls",
      content: (
        <Typography>
          Demand controls allow you to change settings that affect the demand
          data.
        </Typography>
      ),
    },
    {
      selector: ".demand-density-control",
      content: (
        <Typography>
          Demand density normalizes demand by the size of the block group,
          making it easier to identify locations with high demand relative to
          their size.
          <br />
          <br />
          Demand is currently shown in{" "}
          {useDemandDensity ? "kWh per square mile" : "kWh"}. Toggling it will
          show demand in {useDemandDensity ? "kWh" : "kWh per square mile"}.
        </Typography>
      ),
    },
    {
      selector: ".demand-legend",
      content: (
        <Typography>
          The legend will update to reflect the correct units in alignment with
          the demand density control.
        </Typography>
      ),
    },
    {
      selector: ".season-control",
      content: (
        <Typography>
          The current season can be changed here. Summer and winter are peak
          seasons, while spring and fall are shoulder seasons.
        </Typography>
      ),
    },
    {
      selector: ".demand-type-control",
      content: (
        <Typography>
          The displayed types of charging demand can be controlled here.
          Multiple selected options will display the total demand of all
          selected demand types.
        </Typography>
      ),
    },
    {
      selector: ".demand-hour-control",
      content: (
        <Typography>
          The demand for the selected hour can be changed here. All times are in
          the local time zone. You can view the total demand across all hours by
          toggling the "All day" checkbox.
        </Typography>
      ),
    },
    {
      selector: ".animation-button",
      content: (
        <Typography>
          An animation cycling through the hourly demand can be viewed by
          clicking this button.
        </Typography>
      ),
    },
    {
      selector: ".layer-controls",
      content: (
        <Typography>
          The available map overlays can be toggled in this panel.
        </Typography>
      ),
    },
    {
      selector: ".download-data-button",
      content: (
        <Typography>
          The demand for this growth scenario can be downloaded by clicking this
          button. The downloaded data will reflect the currently selected
          controls in the side panel, and includes the map geometry in the
          GeoJSON format.
        </Typography>
      ),
    },
    {
      selector: ".mapboxgl-ctrl-geocoder",
      content: (
        <Typography>
          You can search for an address with this search button.
        </Typography>
      ),
    },
    {
      selector: ".recenter-button",
      content: (
        <Typography>
          Recenter the map at any time by clicking this button.
        </Typography>
      ),
    },
  ];

  const mapLoading =
    props.loading ||
    loading ||
    loadingDemand ||
    loadingAllDayDemand ||
    scaleYear !== props.year;

  const isTxPPC = ["TX", "CPS", "NBU", "LCRA", "AE"].includes(
    getStateAbbrFromStateName(props.location?.name || "")
  );

  const [filteredGrowthScenarios, setFilteredGrowthScenarios] = useState<
    GrowthScenario[]
  >([]);

  useEffect(() => {
    const filteredGs = props.growthScenarios?.filter((y) => y.isldv === true);
    setFilteredGrowthScenarios(filteredGs || []);
    if (filteredGs && filteredGs.length > 0) {
      const selectedGs =
        props.selectedGrowthScenario && props.selectedGrowthScenario.isldv
          ? props.selectedGrowthScenario.id
          : filteredGs[0].id;
      if (props.setSelectedGrowthScenarioId) {
        props.setSelectedGrowthScenarioId(selectedGs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.growthScenarios]);

  function getPopupContent(popupInfo: PopupInfo) {
    switch (markerType) {
      case "block-groups":
        return getBlockGroupPopupContent(
          popupInfo.feature,
          scaledDemand,
          0,
          useDemandDensity ? "demandDensity" : "demand"
        );
      case "substations":
        return getSubstationPopupContent(popupInfo.feature);
      case "unclustered-point":
        return <ChargingStationPopupContent feature={popupInfo.feature} />;
      case "poverty":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "non-white":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "mfh":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "block-groups-outline":
        return (
          <>
            <Typography align="center">
              This is a block group boundary.
            </Typography>
            <Typography align="center">
              Click on a block group or marker to view more information.
            </Typography>
          </>
        );
      default:
        return (
          <GenericGeoJsonFeaturePopupContent feature={popupInfo.feature} />
        );
    }
  }

  function getBlockGroupPopupContent(
    blockGroup: any,
    scaledDemand: any,
    existingChargerCoverage: any,
    currentView: string
  ) {
    const geoid = blockGroup.properties.geoid;
    const area = blockGroup.properties.area;
    const demandkWh = scaledDemand
      ? scaledDemand[blockGroup.properties.geoid] ?? 0
      : 0; // if demand undefined, set 0
    const existingChargerCoveragekWh = existingChargerCoverage
      ? existingChargerCoverage[blockGroup.properties.geoid] ?? 0
      : 0; // if props.existingChargerCoverage undefined, set 0
    return (
      <BlockGroupPopupContent
        geoid={geoid}
        area={area}
        demand={demandkWh}
        existingChargerCoverage={existingChargerCoveragekWh}
        currentView={currentView}
      />
    );
  }

  function getSubstationPopupContent(substation: any) {
    return (
      <SubstationPopupContent
        name={substation.properties.name}
        status={substation.properties.status}
        lines={substation.properties.num_lines}
        maxVoltage={substation.properties.max_voltage}
        minVoltage={substation.properties.min_voltage}
        maxInfer={substation.properties.max_infer}
        minInfer={substation.properties.min_infer}
      />
    );
  }

  return (
    <Stack direction={"row"} sx={{ height: "100%" }}>
      <Stack sx={{ width: "462px", height: "100%" }}>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          sx={{ padding: "30px", overflowY: "auto" }}
        >
          <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography variant="controlTitle">Charging Demand</Typography>
              <Button
                onClick={() => {
                  setSteps(tourSteps);
                  setCurrentStep(0);
                  setIsOpen(true);
                }}
                color="info"
                startIcon={<HelpOutlineIcon />}
                variant={"outlined"}
                size={"small"}
              >
                Tutorial
              </Button>
            </Stack>
            <AccessControl permission={"read:block_group_popups"}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"flex-end"}
                spacing={1}
              >
                <InfoIcon color="info" fontSize="small" />
                <Typography>
                  Click a block group to see detailed information
                </Typography>
              </Stack>
            </AccessControl>
          </Box>
          <Stack spacing={1} className="vehicle-population-controls">
            <HelpTooltip
              title={
                "A growth scenario and year, as specified in the Timeline tab, determine the vehicle population "
              }
            >
              <Typography variant="h3">Simulation Controls</Typography>
            </HelpTooltip>
            <GrowthScenarioControl
              selectedGrowthScenario={
                filteredGrowthScenarios?.find(
                  (scenario) => scenario.id === props.selectedGrowthScenario?.id
                ) || undefined
              }
              growthScenarios={filteredGrowthScenarios}
              onChange={props.onGrowthScenarioChange}
              disabled={playingAnimation}
            />
            <TrafficModelControl
              selectedTrafficModel={props.selectedTrafficModel}
              trafficModels={props.trafficModels}
              onChange={handleTrafficModelChange}
              disabled={playingAnimation}
            />
            {lightDutyVehicleClass === undefined && (
              <Alert severity="warning">
                <AlertTitle>Incompatible Growth Scenario</AlertTitle>
                The selected growth scenario does not have light-duty demand
                data. Please select a different growth scenario.
              </Alert>
            )}
            <YearControl
              value={props.year}
              years={range(2020, 2051)}
              onChange={props.onYearChange}
              disabled={playingAnimation}
            />
            <EvPenetration
              numEvs={currentYearVehicleClassData?.numEvs}
              percent={currentYearVehicleClassData?.evFractionOfAllVehicles}
            />
          </Stack>
          <Stack spacing={1} className={"demand-controls"}>
            <Typography variant="h3">Demand Controls</Typography>
            <DemandDensityControl
              checked={useDemandDensity}
              onChange={handleUseDemandDensityChange}
            />
            <SeasonControl
              ac={props.ac}
              onChange={props.onAcChange}
              chargingDemandSimulations={props.chargingDemandSimulations}
              disabled={playingAnimation}
            />
            <DemandTypeControl
              preloading={preloading}
              disabled={playingAnimation}
              demandTypes={demandTypes}
              onDemandTypesChange={handleDemandTypesChange}
            />
            <DemandHourControl
              preloading={preloading}
              playingAnimation={playingAnimation}
              allDayCheckbox={allDayCheckbox}
              onAllDayCheckboxChange={handleAllDayCheckboxChange}
              onAnimationButtonClick={handleAnimationButtonClick}
              hour={hour}
              onHourChange={handleHourChange}
            />
          </Stack>
          <Stack spacing={1} className={"layer-controls"}>
            <Typography variant="h3">Layer Controls</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("substations")}
                  onChange={handleLayerChange("substations")}
                  name="block-groups"
                />
              }
              label="Substations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("charging-stations")}
                  onChange={handleLayerChange("charging-stations")}
                  name="block-groups"
                />
              }
              label="Charging Stations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("poverty")}
                  onChange={handleLayerChange("poverty")}
                  name="block-groups"
                />
              }
              label="Poverty"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("non-white")}
                  onChange={handleLayerChange("non-white")}
                  name="block-groups"
                />
              }
              label="Non-White"
            />
            {!isTxPPC && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeLayers.includes("mfh")}
                    onChange={handleLayerChange("mfh")}
                    name="block-groups"
                  />
                }
                label="Multifamily Housing"
              />
            )}
          </Stack>
        </Stack>
        <StickyBottomBox>
          <DownloadButton
            onClick={handleDownloadButtonClick}
            error={errorDownloading}
            loading={downloading}
          />
        </StickyBottomBox>
      </Stack>
      <Box sx={{ height: "100%", flex: 1, position: "relative" }}>
        <Backdrop
          sx={{
            color: "#FFFFFF",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={mapLoading}
        >
          <Stack alignItems={"center"} spacing={2}>
            <CircularProgress color="inherit" />
            <Collapse in={loadingDemand}>
              <Typography variant="h3">Loading demand...</Typography>
            </Collapse>
            <Collapse in={props.loading}>
              <Typography variant="h3">Loading data...</Typography>
            </Collapse>
          </Stack>
        </Backdrop>
        <Stack
          spacing={2}
          sx={{
            position: "absolute",
            margin: "8px",
            zIndex: "1000",
            top: 0,
            right: 0,
            height: `calc(100% - 20px - 8px * 2)`,
            pointerEvents: "none",
          }}
          alignItems={"flex-end"}
          direction={"column"}
        >
          <Box
            sx={{
              borderRadius: "10px",
              backgroundColor:
                displayMode === "dark"
                  ? "hsla(0,0%,100%,.8)"
                  : "hsla(0,0%,0%,.8)",
              pointerEvents: "auto",
              paddingRight: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={displayMode === "dark"}
                  onChange={handleDisplayModeChange}
                />
              }
              label="Dark Mode"
              labelPlacement="start"
              componentsProps={{
                typography: {
                  color: displayMode === "dark" ? "black" : "white",
                },
              }}
            />
          </Box>
        </Stack>
        <WidgetContainer>
          {useDemandDensity ? (
            <Widget sx={{ width: "303px" }}>
              <Legend
                title={"Energy Density"}
                colors={chargingDemandLegend}
                units={"kWh/mi²"}
                className={"demand-legend"}
              />
            </Widget>
          ) : (
            <Widget sx={{ width: "270px" }}>
              <Legend
                title={"Energy"}
                colors={chargingDemandLegend}
                units={"kWh"}
                className={"demand-legend"}
              />
            </Widget>
          )}
          {activeLayers.includes("charging-stations") && (
            <Widget sx={{ width: "210px" }}>
              <Legend
                title={"Charging Stations"}
                colors={chargingStationsLegend}
                units={""}
              />
            </Widget>
          )}
          {activeLayers.includes("poverty") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Poverty"}
                colors={povertyLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
          {activeLayers.includes("non-white") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Non-White"}
                colors={nonWhiteLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
          {activeLayers.includes("mfh") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Multifamily Housing"}
                colors={mfhLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
        </WidgetContainer>
        <Map
          id="map"
          {...viewport}
          onMove={(evt) => setViewport(evt.viewState)}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          onClick={onClick}
          mapStyle={
            displayMode === "dark"
              ? "mapbox://styles/mapbox/dark-v11"
              : "mapbox://styles/mapbox/light-v11"
          }
          interactiveLayerIds={[
            blockGroupLayerStyle.id || "",
            blockGroupOutlineLayerStyle.id || "",
            substationsLayerStyle.id || "",
            chargingStationsLayerStyle.id || "",
            clustersLayerStyle.id || "",
            unclusteredPointLayerStyle.id || "",
            povertyLayerStyle.id || "",
            nonWhiteLayerStyle.id || "",
            mfhLayerStyle.id || "",
          ]}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          cursor={cursor}
          ref={mapRef}
        >
          <GeocoderControl
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN ?? ""}
            position="top-left"
          />
          <NavigationControl position={"top-left"} />
          <HomeButton
            // when the location changes, we need to render a new HomeButton
            // because otherwise the listeners do not get updated with the new
            // value of location. The HomeButton's onRemove() will remove the
            // previous element from the map
            key={props.location?.name}
            onClick={handleRecenterClick}
          />
          {activeLayers.includes("block-groups") && scaledDemand && (
            <Source id="block-groups" type="geojson" data={props.blockGroups}>
              <Layer beforeId="waterway-label" {...blockGroupLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("block-groups") && (
            <Source
              id="block-groups-outline"
              type="geojson"
              data={props.blockGroups}
            >
              <Layer
                beforeId="waterway-label"
                {...blockGroupOutlineLayerStyle}
              />
            </Source>
          )}
          {activeLayers.includes("substations") && (
            <Source id="substations" type="geojson" data={props.substations}>
              <Layer beforeId="waterway-label" {...substationsLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("charging-stations") && (
            <Source
              id="clusters"
              type="geojson"
              data={props.chargingStations}
              cluster={true}
              clusterMaxZoom={13}
              clusterRadius={50}
            >
              <Layer {...clustersLayerStyle} />
              <Layer {...clusterCountLayerStyle} />
              <Layer {...unclusteredPointLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("poverty") && (
            <Source id="poverty" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...povertyLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("non-white") && (
            <Source id="non-white" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...nonWhiteLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("mfh") && (
            <Source id="mfh" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...mfhLayerStyle} />
            </Source>
          )}
          {popupInfo && (
            <Popup
              style={{ zIndex: 1100 }}
              anchor={anchor}
              longitude={popupInfo.lng}
              latitude={popupInfo.lat}
              onClose={() => setPopupInfo(undefined)}
              maxWidth={"none"}
              closeButton={false}
            >
              {getPopupContent(popupInfo)}
            </Popup>
          )}
        </Map>
      </Box>
    </Stack>
  );
}

export default DemandPage;
