import { Box, Divider, Stack, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { FleetScenarioChargerLevel, FleetScenarioVehicle } from "types/fleets";

export type FleetOverviewProps = {
  scope?: string[];
  vehiclePurchaseSuggestions?: FleetScenarioVehicle[];
  chargerPurchaseSuggestions?: FleetScenarioChargerLevel[];
};

function FleetOverview({
  scope,
  vehiclePurchaseSuggestions,
  chargerPurchaseSuggestions,
}: FleetOverviewProps) {
  const vehicleColumns: GridColumns = [
    {
      field: "reference_vehicle",
      headerName: "Reference Make/Model",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "num_electric_vehicles",
      headerName: "Quantity (EV)",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "num_ice_vehicles",
      headerName: "Quantity (ICE)",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "vehicle_costs",
      headerName: "Total Capex (USD)",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(params.value)),
      minWidth: 140,
      flex: 1,
    },
  ];

  const chargerColumns: GridColumns = [
    {
      field: "reference_charger",
      headerName: "Reference Make/Model",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "capacity",
      headerName: "Level (KW)",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "num_of_chargers",
      headerName: "Quantity",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "charger_cost",
      headerName: "Total Capex (USD)",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(params.value)),
      minWidth: 140,
      flex: 1,
    },
  ];

  const filteredVehicleRows =
    scope && scope.includes("Vehicles") ? vehiclePurchaseSuggestions : [];
  const filteredChargerRows =
    scope && scope.includes("Chargers/EVSE") ? chargerPurchaseSuggestions : [];

  return (
    <Box>
      <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
        Fleet Overview
      </Typography>
      <Stack spacing={2} divider={<Divider />}>
        <Stack spacing={1}>
          <Typography
            variant="h3"
            color={"GrayText"}
            sx={{ marginTop: "15px", fontWeight: "bold" }}
          >
            Vehicles
          </Typography>
          <Box sx={{ height: "300px" }}>
            <DataGrid
              rows={filteredVehicleRows ?? []}
              columns={vehicleColumns}
              checkboxSelection={true}
              loading={false}
              keepNonExistentRowsSelected={false}
            />
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="h3"
            color={"GrayText"}
            sx={{ fontWeight: "bold" }}
          >
            Chargers
          </Typography>
          <Box sx={{ height: "300px" }}>
            <DataGrid
              rows={filteredChargerRows ?? []}
              columns={chargerColumns}
              checkboxSelection={true}
              loading={false}
              keepNonExistentRowsSelected={false}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FleetOverview;
