import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogProps,
  FormLabel,
  IconButton,
  RadioGroup,
  Radio,
  Alert,
  Grid,
  FormGroup,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import TimeSlider from "components/TimeSlider";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useState } from "react";
import {
  FleetMix,
  lightDutyVehicleTypes,
  mediumDutyVehicleTypes,
  heavyDutyVehicleTypes,
  FleetMixVehicle,
} from "dashboard/fleets/fleet-mix";
import { FleetMixVehicleTypeConfiguration } from "../FleetMixVehicleTypeConfiguration";
import { LoadingButton } from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
import {
  FleetScenario,
  FleetScenarioChargerLevelInput,
  FleetScenarioInput,
  FleetScenarioVehicleInput,
} from "types/fleets";
import { createFleetScenario } from "api/fleets/create-fleet-scenario";
import { useAccessToken } from "utils/get-access-token";

export type AdvancedDialogProps = {
  DialogProps: DialogProps;
};
export function AdvancedDialog({ DialogProps }: AdvancedDialogProps) {
  const steps = [
    "Provide Fleet Details",
    "Review Vehicle and Financial Assumptions",
  ];
  return (
    <Dialog {...DialogProps} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent={"space-between"}>
          Create a New Scenario
          <Stepper activeStep={0} sx={{ width: "600px" }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ marginTop: "0.5em" }}>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <TextField
                required
                label="SMUD Customer ID"
                sx={{ width: "32%" }}
                value={"12345678"}
                size="small"
              />
              <TextField
                required
                label="Planning Horizon (years)"
                type="number"
                sx={{ width: "32%" }}
                value={15}
                size="small"
              />
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h2">Fleet Information</Typography>
                <Button size="small" variant="outlined">
                  <UploadFileIcon sx={{ marginRight: "6px" }} /> Upload Fleet
                  Information
                </Button>
              </Stack>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Stack spacing={2}>
                    <Typography variant="h3">Vehicle 1</Typography>
                    <Stack spacing={2} direction="row">
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Make</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Make"
                          size="small"
                        >
                          <MenuItem value="1">Ford</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Model</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Model"
                          size="small"
                        >
                          <MenuItem value="1">F-150</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Year</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Year"
                          size="small"
                        >
                          <MenuItem value="1">2019</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Trim</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Trim"
                          size="small"
                        >
                          <MenuItem value="1">XLT</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">
                          Engine Size
                        </InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Engine Size"
                          size="small"
                        >
                          <MenuItem value="1">3.5L</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        type="number"
                        label="Annual Operating Costs"
                        InputProps={{
                          startAdornment: <Typography>$</Typography>,
                        }}
                        size="small"
                        value={1977.38}
                        sx={{
                          width: "22%",
                        }}
                      />
                      <TextField
                        type="number"
                        label="Depreciation Rate"
                        InputProps={{
                          endAdornment: <Typography>%</Typography>,
                        }}
                        size="small"
                        value={15.1}
                        sx={{
                          width: "20%",
                        }}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        Edit Purchase Information &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                          flex: 1,
                        }}
                      >
                        Edit Daily Schedule &gt;
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="h3">Vehicle 2</Typography>
                    <Stack spacing={2} direction="row">
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Make</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Make"
                          size="small"
                        >
                          <MenuItem value="1">Ford</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Model</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Model"
                          size="small"
                        >
                          <MenuItem value="1">F-250</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Year</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Year"
                          size="small"
                        >
                          <MenuItem value="1">2012</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">Trim</InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Trim"
                          size="small"
                        >
                          <MenuItem value="1">XL</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ width: "25%" }}>
                        <InputLabel id="light-duty-label">
                          Engine Size
                        </InputLabel>
                        <Select
                          value={"1"}
                          labelId="light-duty-label"
                          label="Engine Size"
                          size="small"
                        >
                          <MenuItem value="1">7.3L</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        type="number"
                        label="Annual Operating Costs"
                        InputProps={{
                          startAdornment: <Typography>$</Typography>,
                        }}
                        size="small"
                        value={2395.03}
                        sx={{
                          width: "22%",
                        }}
                      />
                      <TextField
                        type="number"
                        label="Depreciation Rate"
                        InputProps={{
                          endAdornment: <Typography>%</Typography>,
                        }}
                        size="small"
                        value={8.1}
                        sx={{
                          width: "20%",
                        }}
                      />
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        Edit Purchase Information &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          border: "1px solid #BBB",
                          color: "rgba(0, 0, 0, 0.87)",
                          flex: 1,
                        }}
                      >
                        Edit Daily Schedule &gt;
                      </Button>
                    </Stack>
                  </Stack>
                  <Button size="small" sx={{ width: "250px", height: "20px" }}>
                    <AddIcon />
                    Add another vehicle
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2">Viable Chargers</Typography>
            <Stack direction="row">
              <Typography>
                Is there a maximum number of chargers you are willing to
                purchase/have space for?
              </Typography>
              <TextField
                label={"Maximum Number of Chargers"}
                value={32}
                type={"number"}
                sx={{ width: "400px" }}
              />
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <FormControlLabel
                  control={<Checkbox />}
                  label={"Level 2 Only"}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox />}
                  label={"DC Fast Only"}
                  checked
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox />}
                  label={"DC Fast + Level 2"}
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"}>Cancel</Button>
        <Button variant="contained">Next</Button>
      </DialogActions>
    </Dialog>
  );
}

export type BasicDialogProps = {
  fleetScenarios: FleetScenario[];
  DialogProps: DialogProps;
  onSubmit: () => void;
};

const CHARGER_LEVELS: FleetScenarioChargerLevelInput[] = [
  {
    capacity: 15,
    charger_type: "Level 2",
    checked: false,
  },
  {
    capacity: 50,
    charger_type: "DCFC Option 1",
    checked: false,
  },
  {
    capacity: 150,
    charger_type: "DCFC Option 2",
    checked: false,
  },
];

type FuelMaintenanceInfo = {
  costOfICE: number;
  annualICEMaintenance: number;
  annualEVMaintenance: number;
  avergeEVEnergyCharge: number;
  averageEVDemandCharge: number;
};

type FleetError = {
  scenarioName: boolean;
  planningHorizon: boolean;
  costOfICE: boolean;
  annualICEMaintenance: boolean;
  annualEVMaintenance: boolean;
  avergeEVEnergyCharge: boolean;
  averageEVDemandCharge: boolean;
  isError: boolean;
  vehicleError: boolean;
  scopeError: boolean;
};

const describeCompany = {
  "1": "Company bought in on fleet electrification",
  "2": "Single champion for electrification in company",
  "3": "Reluctance towards electrification",
};

export function BasicDialog({
  DialogProps,
  onSubmit,
  fleetScenarios,
}: BasicDialogProps) {
  const { getToken } = useAccessToken();
  const [scenarioName, setScenarioName] = useState("");
  const [planningHorizon, setPlanningHorizon] = useState(12);
  const [operatingHours, setOperatingHours] = useState([9, 17]);
  const [application, setApplication] = useState("city");
  const [maxNumChargers, setMaxNumChargers] = useState(32);
  const [attitude, setAttitude] = useState<string>("1");
  const [ownership, setOwnership] = useState<string>("Owned");
  const [multipleLocation, setMultipleLocation] = useState<string>("true");
  const [depotRoute, setDepotRoute] = useState<string>("recurring");

  const [fleetMix, setFleetMix] = useState<FleetMix>({
    lightDutyVehicles: [],
    mediumDutyVehicles: [],
    heavyDutyVehicles: [],
  });
  const [loading, setLoading] = useState(false);

  const [fuelMaintenanceInfo, setFuelMaintenanceInfo] =
    useState<FuelMaintenanceInfo>({
      costOfICE: 4,
      annualEVMaintenance: 3,
      annualICEMaintenance: 5,
      averageEVDemandCharge: 2.38,
      avergeEVEnergyCharge: 0.05,
    });

  const [fleetError, setFleetError] = useState<FleetError>({
    scenarioName: false,
    planningHorizon: false,
    costOfICE: false,
    annualICEMaintenance: false,
    annualEVMaintenance: false,
    avergeEVEnergyCharge: false,
    averageEVDemandCharge: false,
    isError: true,
    vehicleError: false,
    scopeError: false,
  });

  const [step, setStep] = useState(1);

  const [scope, setScope] = useState<string[]>([]);
  const [chargingLocation, setChargingLocation] = useState<string[]>([
    "At Facility",
  ]);
  const [chargerValues, setChargerValues] =
    useState<FleetScenarioChargerLevelInput[]>(CHARGER_LEVELS);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleChargerValues = (
    index: number,
    value: FleetScenarioChargerLevelInput
  ) => {
    const updatedValues = [...chargerValues];
    updatedValues[index] = value;
    setChargerValues(updatedValues);
  };
  const delayOnSubmit = async () => {
    const apiToken = await getToken();
    if (scope.length === 0) {
      setFleetError({ ...fleetError, scopeError: true, isError: true });
      return;
    }
    const vehicles: FleetScenarioVehicleInput[] = [];
    const chargers: FleetScenarioChargerLevelInput[] = [];
    for (const veh of fleetMix.lightDutyVehicles) {
      vehicles.push({
        fleet_type: "LDV",
        vehicle_type: veh.type,
        num_electric_vehicles: veh.numEv,
        num_ice_vehicles: veh.numIce,
        total_annual_mileage: veh.annualMileage,
      });
    }

    for (const veh of fleetMix.mediumDutyVehicles) {
      vehicles.push({
        fleet_type: "MDV",
        vehicle_type: veh.type,
        num_electric_vehicles: veh.numEv,
        num_ice_vehicles: veh.numIce,
        total_annual_mileage: veh.annualMileage,
      });
    }

    for (const veh of fleetMix.heavyDutyVehicles) {
      vehicles.push({
        fleet_type: "HDV",
        vehicle_type: veh.type,
        num_electric_vehicles: veh.numEv,
        num_ice_vehicles: veh.numIce,
        total_annual_mileage: veh.annualMileage,
      });
    }

    for (const charger of chargerValues) {
      if (charger.checked) {
        chargers.push({
          capacity: charger.capacity,
          charger_type: charger.charger_type,
        });
      }
    }

    const fleetScenario: FleetScenarioInput = {
      name: scenarioName,
      operating_hours: operatingHours,
      application: application,
      max_no_of_chargers: maxNumChargers,
      fuel_cost: fuelMaintenanceInfo.costOfICE,
      annual_ev_maintenance: fuelMaintenanceInfo.annualEVMaintenance,
      annual_ice_maintenance: fuelMaintenanceInfo.annualICEMaintenance,
      avg_ev_demand_charge: fuelMaintenanceInfo.averageEVDemandCharge,
      avg_ev_energy_charge: fuelMaintenanceInfo.avergeEVEnergyCharge,
      attitude: describeCompany[attitude as keyof typeof describeCompany],
      charge_location: chargingLocation,
      multiple_locations: multipleLocation === "true" ? true : false,
      ownership_status: ownership,
      scope: scope,
      planning_horizon: planningHorizon,
      fleetChargerLevels: chargers,
      fleetVehicles: vehicles,
      depot_route: depotRoute,
    };

    try {
      await createFleetScenario(fleetScenario, apiToken);
    } catch (e) {
      setSnackbarMessage("Error while creating the fleet scenario.");
      setShowSnackbar(true);
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      onSubmit();
    }, 2000);
  };

  const validateVehicleFields = () => {
    let isValid = true;
    const newFleetMix: FleetMix = { ...fleetMix };
    // Validate light duty vehicles
    if (fleetMix.lightDutyVehicles.length > 0) {
      const ldv = validateVehicles(fleetMix.lightDutyVehicles);
      isValid = ldv.isValid;
      newFleetMix.lightDutyVehicles = ldv.vehicles;
      if (!isValid) {
        setFleetMix(newFleetMix);
        return isValid;
      }
    }

    // Validate medium duty vehicles
    if (fleetMix.mediumDutyVehicles.length > 0) {
      const mdv = validateVehicles(fleetMix.mediumDutyVehicles);
      isValid = mdv.isValid;
      newFleetMix.mediumDutyVehicles = mdv.vehicles;
      if (!isValid) {
        setFleetMix(newFleetMix);
        return isValid;
      }
    }

    // Validate heavy duty vehicles
    if (fleetMix.heavyDutyVehicles.length > 0) {
      const hdv = validateVehicles(fleetMix.heavyDutyVehicles);
      isValid = hdv.isValid;
      newFleetMix.heavyDutyVehicles = hdv.vehicles;
      if (!isValid) {
        setFleetMix(newFleetMix);
        return isValid;
      }
    }
    return isValid;
  };

  const validateVehicles = (vehicles: FleetMixVehicle[]) => {
    for (const vehicle of vehicles) {
      if (!vehicle.type) {
        vehicle.typeError = true;
        vehicle.errorMessage = "Please select Vehicle Types.";
        return { isValid: false, vehicles };
      }

      if (vehicle.numEv <= 0 && vehicle.numIce <= 0) {
        vehicle.numEvError = true;
        vehicle.numIceError = true;
        vehicle.errorMessage = "Please Enter Number Electric or Number ICE.";
        return { isValid: false, vehicles };
      }

      if (!vehicle.annualMileage) {
        vehicle.annualMileageError = true;
        vehicle.errorMessage = "Please Enter Total Annual Mileage.";
        return { isValid: false, vehicles };
      }
    }
    return { isValid: true, vehicles };
  };

  const handleNext = () => {
    if (step === 1) {
      if (!scenarioName) {
        setFleetError({ ...fleetError, scenarioName: true, isError: true });
        return;
      }
      if (planningHorizon < 0) {
        setFleetError({ ...fleetError, planningHorizon: true, isError: true });
        return;
      }
      if (
        fleetMix.heavyDutyVehicles.length === 0 &&
        fleetMix.mediumDutyVehicles.length === 0 &&
        fleetMix.lightDutyVehicles.length === 0
      ) {
        setFleetError({ ...fleetError, vehicleError: true, isError: true });
        return;
      }
      if (!validateVehicleFields()) {
        return;
      }

      if (fuelMaintenanceInfo.costOfICE < 0) {
        setFleetError({
          ...fleetError,
          costOfICE: true,
          isError: true,
        });
        return;
      }
      if (fuelMaintenanceInfo.annualICEMaintenance < 0) {
        setFleetError({
          ...fleetError,
          annualICEMaintenance: true,
          isError: true,
        });
        return;
      }
      if (fuelMaintenanceInfo.annualEVMaintenance < 0) {
        setFleetError({
          ...fleetError,
          annualEVMaintenance: true,
          isError: true,
        });
        return;
      }
      if (fuelMaintenanceInfo.avergeEVEnergyCharge < 0) {
        setFleetError({
          ...fleetError,
          avergeEVEnergyCharge: true,
          isError: true,
        });
        return;
      }
      if (fuelMaintenanceInfo.averageEVDemandCharge < 0) {
        setFleetError({
          ...fleetError,
          averageEVDemandCharge: true,
          isError: true,
        });
        return;
      }
      setStep(2);
    }
    return;
  };

  const handleAddLightDutyVehicle = () => {
    setFleetError({ ...fleetError, vehicleError: false, isError: false });
    setFleetMix({
      ...fleetMix,
      lightDutyVehicles: [
        ...fleetMix.lightDutyVehicles,
        {
          id: uuidv4(),
          type: "",
          numEv: 0,
          numIce: 0,
          annualMileage: 0,
          typeError: false,
          numEvError: false,
          numIceError: false,
          annualMileageError: false,
          errorMessage: "",
        },
      ],
    });
  };

  const handleRemoveLightDutyVehicle = (id?: string) => {
    setFleetMix({
      ...fleetMix,
      lightDutyVehicles: fleetMix.lightDutyVehicles.filter(
        (vehicle) => vehicle.id !== id
      ),
    });
  };

  const handleAddMediumDutyVehicle = () => {
    setFleetError({ ...fleetError, vehicleError: false, isError: false });
    setFleetMix({
      ...fleetMix,
      mediumDutyVehicles: [
        ...fleetMix.mediumDutyVehicles,
        {
          id: uuidv4(),
          type: "",
          numEv: 0,
          numIce: 0,
          annualMileage: 0,
          typeError: false,
          numEvError: false,
          numIceError: false,
          annualMileageError: false,
          errorMessage: "",
        },
      ],
    });
  };

  const handleRemoveMediumDutyVehicle = (id?: string) => {
    setFleetMix({
      ...fleetMix,
      mediumDutyVehicles: fleetMix.mediumDutyVehicles.filter(
        (vehicle) => vehicle.id !== id
      ),
    });
  };

  const handleAddHeavyDutyVehicle = () => {
    setFleetError({ ...fleetError, vehicleError: false, isError: false });
    setFleetMix({
      ...fleetMix,
      heavyDutyVehicles: [
        ...fleetMix.heavyDutyVehicles,
        {
          id: uuidv4(),
          type: "",
          numEv: 0,
          numIce: 0,
          annualMileage: 0,
          typeError: false,
          numEvError: false,
          numIceError: false,
          annualMileageError: false,
          errorMessage: "",
        },
      ],
    });
  };

  const handleChangeHeavyDutyVehicle = (vehicle: FleetMixVehicle) => {
    setFleetMix({
      ...fleetMix,
      heavyDutyVehicles: fleetMix.heavyDutyVehicles.map((v) =>
        v.id === vehicle.id ? vehicle : v
      ),
    });
  };

  const handleRemoveHeavyDutyVehicle = (id?: string) => {
    setFleetMix({
      ...fleetMix,
      heavyDutyVehicles: fleetMix.heavyDutyVehicles.filter(
        (vehicle) => vehicle.id !== id
      ),
    });
  };

  const hasTeslaSemi = fleetMix.heavyDutyVehicles.some((vehicle) =>
    vehicle.type?.includes("Tesla Semi")
  );
  const hasError = application === "off-road" && hasTeslaSemi;

  return (
    <Dialog {...DialogProps} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography variant="h2">
            {step === 1
              ? "Create a New Scenario"
              : "Tell us more about you (optional)"}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {step === 1 ? (
          <Stack spacing={2} sx={{ marginTop: "0.5em" }}>
            <Stack spacing={2}>
              <Stack direction={"row"} spacing={2}>
                <TextField
                  required
                  label="Scenario Name"
                  sx={{ width: "68%" }}
                  value={scenarioName}
                  onChange={(e) => {
                    setScenarioName(e.target.value);
                    if (
                      fleetScenarios.some(
                        (fleet) =>
                          fleet.name.toLowerCase() ===
                          e.target.value.toLowerCase()
                      )
                    ) {
                      setFleetError({
                        ...fleetError,
                        scenarioName: true,
                        isError: true,
                      });
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      scenarioName: false,
                      isError: false,
                    });
                  }}
                  error={fleetError.scenarioName}
                  helperText={
                    fleetError.scenarioName
                      ? "Please Enter Valid Scenario Name."
                      : ""
                  }
                />
                <TextField
                  required
                  label="Planning Horizon (years)"
                  type="number"
                  sx={{ width: "32%" }}
                  value={planningHorizon}
                  onChange={(e) => {
                    setFleetError({
                      ...fleetError,
                      planningHorizon: false,
                      isError: false,
                    });
                    setPlanningHorizon(Number(e.target.value));
                  }}
                  error={fleetError.planningHorizon}
                  helperText={
                    fleetError.planningHorizon
                      ? "Please Enter Planning Horizon (Years)."
                      : ""
                  }
                />
              </Stack>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="h2">Fleet Characteristics</Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography sx={{ width: "50%" }}>
                      What are the typical operating hours of this fleet?
                    </Typography>
                    <Box sx={{ width: "50%" }}>
                      <TimeSlider
                        duplicateMidnight
                        value={operatingHours}
                        onChange={(e, value) =>
                          setOperatingHours(value as number[])
                        }
                        valueLabelDisplay="auto"
                        step={1}
                      />
                    </Box>
                  </Stack>
                  <FormControl
                    component="fieldset"
                    className="application-control"
                  >
                    <FormLabel>Application</FormLabel>
                    <RadioGroup
                      name="application"
                      value={application}
                      onChange={(e, value) => setApplication(value)}
                    >
                      <Stack direction="row" spacing={2}>
                        <FormControlLabel
                          value="city"
                          control={<Radio />}
                          label="City"
                        />
                        <FormControlLabel
                          value="highway"
                          control={<Radio />}
                          label="Highway"
                        />
                        <FormControlLabel
                          value="off-road"
                          control={<Radio />}
                          label="Off-road"
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Typography variant="h3">Fleet Mix</Typography>
                <Stack spacing={2}>
                  <Stack spacing={2}>
                    <Typography variant="h4">Light Duty</Typography>
                    {fleetMix.lightDutyVehicles.map((vehicle, index) => (
                      <FleetMixVehicleTypeConfiguration
                        key={vehicle.type}
                        vehicle={vehicle}
                        vehicleTypeOptions={lightDutyVehicleTypes}
                        onRemove={() =>
                          handleRemoveLightDutyVehicle(vehicle.id)
                        }
                        onChange={(vehicle) => {
                          const newFleetMix = { ...fleetMix };
                          newFleetMix.lightDutyVehicles[index] = vehicle;
                          setFleetMix(newFleetMix);
                        }}
                      />
                    ))}
                    <Stack direction="row" alignItems="center">
                      <IconButton onClick={handleAddLightDutyVehicle}>
                        <AddCircleOutlineIcon color="primary" />
                      </IconButton>
                      <Button
                        sx={{ height: "30px" }}
                        onClick={handleAddLightDutyVehicle}
                      >
                        Add another vehicle type
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="h4">Medium Duty</Typography>
                    {fleetMix.mediumDutyVehicles.map((vehicle, index) => (
                      <FleetMixVehicleTypeConfiguration
                        key={vehicle.type}
                        vehicle={vehicle}
                        vehicleTypeOptions={mediumDutyVehicleTypes}
                        onRemove={() =>
                          handleRemoveMediumDutyVehicle(vehicle.id)
                        }
                        onChange={(vehicle) => {
                          const newFleetMix = { ...fleetMix };
                          newFleetMix.mediumDutyVehicles[index] = vehicle;
                          setFleetMix(newFleetMix);
                        }}
                      />
                    ))}
                    <Stack direction="row" alignItems="center">
                      <IconButton>
                        <AddCircleOutlineIcon
                          color="primary"
                          onClick={handleAddMediumDutyVehicle}
                        />
                      </IconButton>
                      <Button
                        sx={{ height: "30px" }}
                        onClick={handleAddMediumDutyVehicle}
                      >
                        Add another vehicle type
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="h4">Heavy Duty</Typography>
                    {fleetMix.heavyDutyVehicles.map((vehicle, index) => (
                      <FleetMixVehicleTypeConfiguration
                        key={vehicle.type}
                        vehicle={vehicle}
                        vehicleTypeOptions={heavyDutyVehicleTypes}
                        onRemove={() =>
                          handleRemoveHeavyDutyVehicle(vehicle.id)
                        }
                        onChange={(vehicle) => {
                          const newFleetMix = { ...fleetMix };
                          newFleetMix.heavyDutyVehicles[index] = vehicle;
                          setFleetMix(newFleetMix);
                        }}
                      />
                    ))}
                    <Stack direction="row" alignItems="center">
                      <IconButton>
                        <AddCircleOutlineIcon
                          color="primary"
                          onClick={handleAddHeavyDutyVehicle}
                        />
                      </IconButton>
                      <Button onClick={handleAddHeavyDutyVehicle}>
                        Add another vehicle type
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
                {fleetError.vehicleError && (
                  <Stack>
                    <Typography
                      sx={{ marginTop: -1 }}
                      variant="body1"
                      style={{ color: "#d32f2f" }}
                    >
                      Please add at least one vehicle before continuing.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h2">Viable Chargers</Typography>
              <Stack direction="row">
                <Typography>
                  Is there a maximum number of chargers you are willing to
                  purchase/have space for?
                </Typography>
                <TextField
                  label={"Maximum Number of Chargers"}
                  value={maxNumChargers}
                  onChange={(e) => setMaxNumChargers(Number(e.target.value))}
                  type={"number"}
                  sx={{ width: "400px" }}
                />
              </Stack>

              <FormControl>
                <FormLabel>Charger Levels to Consider</FormLabel>
                <Grid container direction={"row"} spacing={2}>
                  {chargerValues.map((charger, index) => (
                    <Grid item md={2.6}>
                      <Grid container direction={"column"}>
                        <Grid item>
                          <FormControlLabel
                            control={<Checkbox />}
                            label={charger.charger_type}
                            checked={charger.checked}
                            onChange={(e, checked) => {
                              handleChargerValues(index, {
                                ...charger,
                                checked,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            value={charger.capacity}
                            inputProps={{ min: 0 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  KW
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              handleChargerValues(index, {
                                ...charger,
                                capacity: Number(e.target.value),
                              });
                            }}
                            type="number"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h2">
                Fuel and Maintenance Information
              </Typography>
              <Stack spacing={2} direction="row">
                <TextField
                  label="Cost of ICE/Diesel Fuel ($/gal)"
                  value={fuelMaintenanceInfo.costOfICE}
                  inputProps={{ step: "0.01", min: 0 }}
                  sx={{ width: "33%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(".") && value.split(".")[1].length > 2) {
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      costOfICE: false,
                      isError: false,
                    });
                    setFuelMaintenanceInfo({
                      ...fuelMaintenanceInfo,
                      costOfICE: Number(value),
                    });
                  }}
                  size="small"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={fleetError.costOfICE}
                  helperText={
                    fleetError.costOfICE
                      ? "Please enter Cost of ICE/Diesel Fuel."
                      : ""
                  }
                  FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
                />
                <TextField
                  label="Annual ICE maintenance (% of price)"
                  inputProps={{ min: 0 }}
                  value={fuelMaintenanceInfo.annualICEMaintenance}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(".") && value.split(".")[1].length > 2) {
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      annualICEMaintenance: false,
                      isError: false,
                    });
                    setFuelMaintenanceInfo({
                      ...fuelMaintenanceInfo,
                      annualICEMaintenance: Number(value),
                    });
                  }}
                  sx={{ width: "33%" }}
                  size="small"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={fleetError.annualICEMaintenance}
                  helperText={
                    fleetError.annualICEMaintenance
                      ? "Please enter annual ICE maintenance."
                      : ""
                  }
                  FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
                />
                <TextField
                  label="Annual EV maintenance (% of price)"
                  inputProps={{ min: 0 }}
                  value={fuelMaintenanceInfo.annualEVMaintenance}
                  sx={{ width: "33%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(".") && value.split(".")[1].length > 2) {
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      annualEVMaintenance: false,
                      isError: false,
                    });
                    setFuelMaintenanceInfo({
                      ...fuelMaintenanceInfo,
                      annualEVMaintenance: Number(value),
                    });
                  }}
                  size="small"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={fleetError.annualEVMaintenance}
                  helperText={
                    fleetError.annualEVMaintenance
                      ? "Please enter annual EV maintenance."
                      : ""
                  }
                  FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
                />
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  label="Average EV Energy Charge ($/KWh)"
                  value={fuelMaintenanceInfo.avergeEVEnergyCharge}
                  inputProps={{ step: "0.01", min: 0 }}
                  sx={{ width: "32%" }}
                  size="small"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(".") && value.split(".")[1].length > 2) {
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      avergeEVEnergyCharge: false,
                      isError: false,
                    });
                    setFuelMaintenanceInfo({
                      ...fuelMaintenanceInfo,
                      avergeEVEnergyCharge: Number(value),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={fleetError.avergeEVEnergyCharge}
                  helperText={
                    fleetError.avergeEVEnergyCharge
                      ? "Please enter average EV energy charge."
                      : ""
                  }
                  FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
                />
                <TextField
                  label="Average EV Demand Charge ($/KW)"
                  value={fuelMaintenanceInfo.averageEVDemandCharge}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(".") && value.split(".")[1].length > 5) {
                      return;
                    }
                    setFleetError({
                      ...fleetError,
                      averageEVDemandCharge: false,
                      isError: false,
                    });
                    setFuelMaintenanceInfo({
                      ...fuelMaintenanceInfo,
                      averageEVDemandCharge: Number(value),
                    });
                  }}
                  sx={{ width: "32%" }}
                  inputProps={{ step: "0.01", min: 0 }}
                  size="small"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={fleetError.averageEVDemandCharge}
                  helperText={
                    fleetError.averageEVDemandCharge
                      ? "Please enter average EV demand charge."
                      : ""
                  }
                  FormHelperTextProps={{ sx: { marginLeft: "-2px" } }}
                />
                <div style={{ flexGrow: 1 }} />
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} sx={{ marginTop: "0.5em" }}>
            <Stack>
              <Typography variant="h2">Depot Details</Typography>
              <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  minWidth="60%"
                  sx={{ marginTop: "10px" }}
                >
                  Where is your fleet going to charge?
                </Typography>
                <FormGroup sx={{ minWidth: "40%" }} row>
                  <FormControlLabel
                    sx={{ minWidth: "40%" }}
                    control={<Checkbox />}
                    label="At Facility"
                    checked={chargingLocation.includes("At Facility")}
                    onChange={(e, checked) => {
                      if (!checked) {
                        setChargingLocation((s) =>
                          s.filter((sc) => sc !== "At Facility")
                        );
                      } else {
                        setChargingLocation([
                          ...chargingLocation,
                          "At Facility",
                        ]);
                      }
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Off-site"
                    checked={chargingLocation.includes("Off-site")}
                    onChange={(e, checked) => {
                      if (!checked) {
                        setChargingLocation((s) =>
                          s.filter((sc) => sc !== "Off-site")
                        );
                      } else {
                        setChargingLocation([...chargingLocation, "Off-site"]);
                      }
                    }}
                  />
                </FormGroup>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  minWidth="60%"
                  sx={{ marginTop: "10px" }}
                >
                  Is your fleet owned or leased?
                </Typography>
                <RadioGroup
                  value={ownership}
                  defaultValue={"Owned"}
                  onChange={(e, value) => setOwnership(value)}
                  sx={{ minWidth: "40%" }}
                  row
                >
                  <FormControlLabel
                    value="Owned"
                    sx={{ minWidth: "40%" }}
                    control={<Radio />}
                    label="Owned"
                  />
                  <FormControlLabel
                    value="Leased"
                    control={<Radio />}
                    label="Leased"
                  />
                </RadioGroup>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  minWidth="60%"
                  sx={{ marginTop: "10px" }}
                >
                  Do you have multiple depot locations?
                </Typography>
                <RadioGroup
                  value={multipleLocation}
                  defaultValue={"true"}
                  onChange={(e, value) => setMultipleLocation(value)}
                  sx={{ minWidth: "40%" }}
                  row
                >
                  <FormControlLabel
                    value="true"
                    sx={{ minWidth: "40%" }}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  minWidth="60%"
                  sx={{ marginTop: "10px" }}
                >
                  What best describes your common daily routes?
                </Typography>
                <RadioGroup
                  value={depotRoute}
                  defaultValue={"recurring"}
                  onChange={(e, value) => setDepotRoute(value)}
                  sx={{ minWidth: "40%" }}
                  row
                >
                  <FormControlLabel
                    value="recurring"
                    sx={{ minWidth: "40%" }}
                    control={<Radio />}
                    label="Recurring"
                  />
                  <FormControlLabel
                    value="uncommon"
                    control={<Radio />}
                    label="Uncommon"
                  />
                </RadioGroup>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h2">Attitude towards EVs</Typography>
              <Stack>
                <Typography variant="h4">
                  What statement best describes your company?
                </Typography>
                <RadioGroup
                  value={attitude}
                  defaultValue={"1"}
                  onChange={(e, value) => setAttitude(value)}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={describeCompany[1]}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={describeCompany[2]}
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label={describeCompany[3]}
                  />
                </RadioGroup>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h2">Scope</Typography>
              <Stack
                spacing={2}
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FormControl error={fleetError.scopeError}>
                  <FormLabel>Suggest Purchases For</FormLabel>
                  <Stack direction={"row"} spacing={4}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Vehicles"
                      sx={{ marginRight: "-0.2em" }}
                      checked={scope.includes("Vehicles")}
                      onChange={(e, checked) => {
                        setFleetError({
                          ...fleetError,
                          scopeError: false,
                          isError: false,
                        });
                        if (!checked) {
                          setScope((s) => s.filter((sc) => sc !== "Vehicles"));
                        } else {
                          setScope([...scope, "Vehicles"]);
                        }
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Chargers/EVSE"
                      sx={{ marginRight: "-0.2em" }}
                      checked={scope.includes("Chargers/EVSE")}
                      onChange={(e, checked) => {
                        setFleetError({
                          ...fleetError,
                          scopeError: false,
                          isError: false,
                        });
                        if (!checked) {
                          setScope((s) =>
                            s.filter((sc) => sc !== "Chargers/EVSE")
                          );
                        } else {
                          setScope([...scope, "Chargers/EVSE"]);
                        }
                      }}
                    />
                  </Stack>
                </FormControl>
              </Stack>
              {fleetError.scopeError && (
                <Stack>
                  <Typography
                    sx={{ marginTop: -2 }}
                    variant="body1"
                    style={{ color: "#d32f2f" }}
                  >
                    Please add at least one purchase scope to continue.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {hasError && (
          <Alert severity={"error"} sx={{ marginRight: "8px" }}>
            Application "Off-road" and "Tesla Semi" are not compatible. Please
            choose another application or vehicle type.
          </Alert>
        )}
        {step === 1 ? (
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleNext}
            disabled={fleetError.isError}
          >
            Next
          </LoadingButton>
        ) : (
          <>
            <Button variant="outlined" onClick={() => setStep(1)}>
              Back
            </Button>
            <LoadingButton
              variant="contained"
              onClick={delayOnSubmit}
              loading={loading}
              disabled={hasError || fleetError.isError}
            >
              Submit
            </LoadingButton>
          </>
        )}
      </DialogActions>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </Dialog>
  );
}
