import { useTour } from "@reactour/tour";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { loadChargingStationNetworks } from "api/charging_station";
import { loadChargingCapacity } from "api/demand";
import { loadDemand } from "api/demand.js";
import EvPenetration from "components/EvPenetration";
import HelpTooltip from "components/HelpTooltip";
import {
  BlockGroupPopupContent,
  ChargingStationPopupContent,
  DemographicsPopupContent,
  GenericGeoJsonFeaturePopupContent,
  SubstationPopupContent,
} from "dashboard/PopupContent";
import ChargerAccessControl from "dashboard/controls/ChargerAccessControl";
import ChargerLevelControl from "dashboard/controls/ChargerLevelControl";
import DownloadButton from "dashboard/controls/DownloadButton";
import StickyBottomBox from "dashboard/controls/StickyBottomBox";
import HomeButton from "dashboard/depots/HomeButton";
import Legend from "dashboard/depots/Legend";
import Widget from "dashboard/depots/Widget";
import WidgetContainer from "dashboard/depots/WidgetContainer";
import GeocoderControl from "dashboard/depots/geocoder-control";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import { Feature, FeatureCollection } from "geojson";
import {
  Anchor,
  GeoJSONSource,
  Layer,
  LayerProps,
  Map,
  MapRef,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import { Ac } from "types/ac";
import { ChargingDemandSimulation } from "types/charging-demand-simulation";
import {
  GrowthScenario,
  getLightDutyVehicleClass,
} from "types/growth-scenario";
import { Location } from "types/location";
import { TrafficModel } from "types/traffic-model";
import { range } from "utils/array";
import {
  calculateCoveragePercentage,
  scaleChargingCapacityByUtilization,
} from "utils/coverage";
import { scaleDemandByGrowthScenarioYear } from "utils/demand";
import { useAccessToken } from "utils/get-access-token";
import { getStateAbbrFromStateName } from "utils/state-abbr";
import AccessControl from "../AccessControl";
import GrowthScenarioControl from "../controls/GrowthScenarioControl";
import SeasonControl from "../controls/SeasonControl";
import UtilizationControl from "../controls/UtilizationControl";
import YearControl from "../controls/YearControl";
import DemographicsLegend from "./DemographicsLegend";
import { downloadStations } from "./downloadStation";

const EXCLUSIVE_EV_NETWORKS = [
  "Tesla",
  "Tesla Destination",
  "RIVIAN_ADVENTURE",
];

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const ACS_METRIC_POVERTY = 2;
const ACS_METRIC_NONWHITE = 3;
const ACS_METRIC_MULTIFAMILY_HOUSING = 4;

type PopupInfo = {
  feature: Feature;
  x: number;
  y: number;
  lng: number;
  lat: number;
};

export type CoveragePageProps = {
  location?: Location;
  ac?: Ac;
  onAcChange?: (newAc: Ac) => void;
  year?: number;
  onYearChange?: (event: Event) => void;
  growthScenarios?: GrowthScenario[];
  selectedGrowthScenario?: GrowthScenario;
  onGrowthScenarioChange?: (event: Event) => void;
  selectedTrafficModelId?: number;
  selectedTrafficModel?: TrafficModel;
  chargingDemandSimulations?: ChargingDemandSimulation[];
  blockGroups?: FeatureCollection;
  demographics?: any;
  chargingStations?: any;
  substations?: any;
  selectedChargingDemandSimulation?: any;
  loading?: boolean;
  setSelectedGrowthScenarioId?: (id: number | undefined) => void;
};

function CoveragePage(props: CoveragePageProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const { getToken } = useAccessToken();

  const [anchor, setAnchor] = useState<Anchor>();
  const [cursor, setCursor] = useState<string>("auto");
  const [viewport, setViewport] = useState({
    latitude: props.location?.center[0],
    longitude: props.location?.center[1],
    zoom: props.location?.zoom,
  });
  const handleRecenterClick = () => {
    setViewport({
      latitude: props.location?.center[0],
      longitude: props.location?.center[1],
      zoom: props.location?.zoom,
    });
  };

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  useEffect(() => {
    // when props.location changes, recenter on new location
    setViewport({
      latitude: props.location?.center[0],
      longitude: props.location?.center[1],
      zoom: props.location?.zoom,
    });
  }, [props.location]);

  const [activeLayers, setActiveLayers] = useState<
    (
      | "block-groups-outline"
      | "substations"
      | "charging-stations"
      | "poverty"
      | "non-white"
      | "mfh"
      | "charger-coverage"
    )[]
  >(["block-groups-outline", "charging-stations", "charger-coverage"]);
  const [popupInfo, setPopupInfo] = useState<PopupInfo | undefined>(undefined);
  const [markerType, setMarkerType] = useState<string>("");
  const [displayMode, setDisplayMode] = useState<"light" | "dark">("dark");
  const mapRef = useRef<MapRef>(null);

  const onClick = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const map = mapRef.current?.getMap();
    if (!map) {
      return;
    }
    const {
      features,
      point: { x, y },
      lngLat: { lng, lat },
    } = event;

    try {
      const clusters = map.queryRenderedFeatures(event.point, {
        layers: ["clusters"],
      });

      if (clusters.length) {
        const clusterId = clusters[0].properties?.cluster_id;
        const source = map.getSource("charging-stations") as GeoJSONSource;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          const coordinates =
            clusters[0].geometry.type === "Point"
              ? (clusters[0].geometry.coordinates as [number, number])
              : undefined;

          if (coordinates) {
            map.easeTo({
              center: coordinates,
              zoom: zoom,
              animate: true,
            });
          }
        });
        return;
      }
    } catch (e) {
      console.error(e);
    }

    const hoveredFeature = features && features[0];
    setMarkerType(hoveredFeature?.layer.id || "");

    let position: Anchor = "top";
    if (y > 400) {
      position = "bottom";
    }
    setAnchor(position);
    setPopupInfo(hoveredFeature && { feature: hoveredFeature, x, y, lng, lat });
  }, []);

  const handleDisplayModeChange = () => {
    setLoading(true);
    setDisplayMode(displayMode === "light" ? "dark" : "light");
    setLoading(false);
  };

  const [loadingChargingCapacity, setLoadingChargingCapacity] = useState(false);
  const [chargingCapacity, setChargingCapacity] = useState({});
  const [utilization, setUtilization] = useState<number>(0.3);

  // download data
  const [downloading, setDownloading] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(false);

  const [demand, setDemand] = useState({});
  const [loadingDemand, setLoadingDemand] = useState(false);

  const [allDayDemand, setAllDayDemand] = useState({});
  const [loadingAllDayDemand, setLoadingAllDayDemand] = useState(false);

  // coverage selectors
  const [includePrivateChargers, setIncludePrivateChargers] = useState(false);
  const [includeExclusiveNetworks, setIncludeExclusiveNetworks] =
    useState(false);
  const [selectedChargerLevels, setSelectedChargerLevels] = useState([
    "l1",
    "l2",
    "dcf",
  ]);
  const [evNetworks, setEvNetworks] = useState([]);

  const lightDutyVehicleClass =
    props.selectedGrowthScenario !== undefined
      ? getLightDutyVehicleClass(props.selectedGrowthScenario)
      : undefined;

  const currentYearVehicleClassData =
    lightDutyVehicleClass?.annualData !== undefined
      ? lightDutyVehicleClass?.annualData?.find(
          (yearData) => yearData.year === props.year
        )
      : undefined;

  const handleUtilizationChange = (utilization: number) => {
    setUtilization(utilization);
  };

  const handleSelectedChargerLevelsChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { value } = event.target as HTMLInputElement;
    if (checked) {
      let newSelectedPlugTypes = [...selectedChargerLevels];
      newSelectedPlugTypes.push(value);
      setSelectedChargerLevels(newSelectedPlugTypes);
    } else {
      let newSelectedPlugTypes = selectedChargerLevels.filter(
        (e) => e !== value
      );
      setSelectedChargerLevels(newSelectedPlugTypes);
    }
  };

  const handleIncludePrivateChargersChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIncludePrivateChargers(!includePrivateChargers);
  };

  const handleIncludeExclusiveNetworksChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIncludeExclusiveNetworks(!includeExclusiveNetworks);
  };

  useEffect(() => {
    const loadNetworks = async () => {
      const token = await getToken();
      const evNetworks = await loadChargingStationNetworks(token);
      setEvNetworks(evNetworks);
    };
    loadNetworks();
  }, []);

  useDebouncedEffect(
    () => {
      // don't attempt to load data if any dependencies are not yet defined,
      // as this side effect relies on other side effects loading data into
      // dependencies
      if (
        [props.selectedChargingDemandSimulation].some(
          (value) => value === undefined
        )
      ) {
        return;
      }

      async function loadData() {
        const apiToken = await getToken();
        setLoadingDemand(true);
        setLoadingAllDayDemand(true);
        try {
          const demand = await loadDemand(
            props.selectedChargingDemandSimulation.id,
            ["Public", "Office"],
            "all",
            props.location?.id,
            apiToken
          );

          setDemand(demand);
          setAllDayDemand(demand);
        } catch (e) {
          console.error(e);
        } finally {
          setLoadingDemand(false);
          setLoadingAllDayDemand(false);
        }

        setLoadingDemand(false);
        setLoadingAllDayDemand(false);
      }
      loadData();
    },
    [props.selectedChargingDemandSimulation, props.location],
    1000
  );

  useDebouncedEffect(
    () => {
      async function loadData() {
        setLoadingChargingCapacity(true);
        const apiToken = await getToken();
        const access = includePrivateChargers
          ? ["public", "private"]
          : ["public"];
        const evNetworksToUse = includeExclusiveNetworks
          ? evNetworks
          : evNetworks.filter(
              (evNetwork) => !EXCLUSIVE_EV_NETWORKS.includes(evNetwork)
            );
        let chargingCapacity = await loadChargingCapacity(
          props.selectedChargingDemandSimulation.id,
          selectedChargerLevels,
          evNetworksToUse,
          access,
          apiToken
        );
        setChargingCapacity(chargingCapacity);
        setLoadingChargingCapacity(false);
      }
      if (props.selectedChargingDemandSimulation) {
        loadData();
      }
    },
    [
      props.selectedChargingDemandSimulation,
      evNetworks,
      selectedChargerLevels,
      includePrivateChargers,
      includeExclusiveNetworks,
    ],
    1000
  );

  const tourSteps = [
    {
      selector: ".mapboxgl-canvas",
      content: (
        <Typography>
          The Charger Coverage page shows how well existing charger
          infrastructure can meet the demand as projected by the growth
          scenario.
        </Typography>
      ),
    },
    {
      selector: ".coverage-legend",
      content: (
        <Typography>
          Coverage is presented as a percentage of the projected charging demand
          that is fulfilled by the existing charging infrastructure.
        </Typography>
      ),
    },
    {
      selector: ".vehicle-population-controls",
      content: (
        <Typography>
          Vehicle population controls allow you to adjust how the vehicle
          population will grow and view the projected demand data for a given
          year. New growth scenarios can be created via the Timeline tab.
        </Typography>
      ),
    },
    {
      selector: ".season-control",
      content: (
        <Typography>
          The current season can be changed here. Summer and winter are peak
          seasons, while spring and fall are shoulder seasons.
        </Typography>
      ),
    },
    {
      selector: ".charger-controls",
      content: (
        <Typography>
          The coverage provided by chargers of different types and access levels
          can be controlled here.
        </Typography>
      ),
    },
    {
      selector: ".utilization-control",
      content: (
        <Typography>
          Charger utilization can be changed here. Charger utilization measures
          what percent of the time a charger is being used. Higher values mean
          that each individual charger will satisfy more of the charging demand.
        </Typography>
      ),
    },
    {
      selector: ".layer-controls",
      content: (
        <Typography>
          The available map overlays can be toggled in this panel.
        </Typography>
      ),
    },
    {
      selector: ".mapboxgl-ctrl-geocoder",
      content: (
        <Typography>
          You can search for an address with this search button.
        </Typography>
      ),
    },
    {
      selector: ".recenter-button",
      content: (
        <Typography>
          Recenter the map at any time by clicking this button.
        </Typography>
      ),
    },
  ];

  // Leaflet is hard to render and freezes the application. This is a separate state that is set on a delay so that
  // the entire application doesn't freeze when the year slider is being changed
  const [scaleYear, setScaleYear] = useState(props.year);
  useDebouncedEffect(
    () => {
      setScaleYear(props.year);
    },
    [props.year],
    1000
  );

  const scaledDemand = useMemo(() => {
    if (
      lightDutyVehicleClass?.annualData !== undefined &&
      scaleYear !== undefined
    ) {
      const allDayDemandSum = Number(
        Object.values(allDayDemand).reduce(
          (sum, val) => Number(sum) + Number(val),
          0
        )
      );
      return scaleDemandByGrowthScenarioYear(
        lightDutyVehicleClass.annualData,
        scaleYear,
        demand,
        allDayDemandSum,
        ["Public", "Office"]
      );
    }
  }, [lightDutyVehicleClass, scaleYear, demand, allDayDemand]);

  const scaledChargingCapacity = useMemo(
    () => scaleChargingCapacityByUtilization(chargingCapacity, utilization),
    [chargingCapacity, utilization]
  );
  const coveragePercentage = useMemo(
    () => calculateCoveragePercentage(scaledDemand, scaledChargingCapacity),
    [scaledDemand, scaledChargingCapacity]
  );

  const coverageLoading =
    loading ||
    props.loading ||
    loadingChargingCapacity ||
    loadingDemand ||
    loadingAllDayDemand ||
    scaleYear !== props.year;

  /* Data Download */

  const downloadChargingStationInfo = async () => {
    if (props.chargingStations) {
      setDownloading(true);
      const downloadSuccess = await downloadStations(
        props.location?.name,
        props.chargingStations
      );
      setErrorDownloading(!downloadSuccess);
      setDownloading(false);
    }
  };

  const isTxPPC = ["TX", "CPS", "NBU", "LCRA", "AE"].includes(
    getStateAbbrFromStateName(props.location?.name || "")
  );

  const [filteredGrowthScenarios, setFilteredGrowthScenarios] = useState<
    GrowthScenario[]
  >([]);

  useEffect(() => {
    const filteredGs = props.growthScenarios?.filter((y) => y.isldv === true);
    setFilteredGrowthScenarios(filteredGs || []);
    if (filteredGs && filteredGs.length > 0) {
      const selectedGs =
        props.selectedGrowthScenario && props.selectedGrowthScenario.isldv
          ? props.selectedGrowthScenario.id
          : filteredGs[0].id;
      if (props.setSelectedGrowthScenarioId) {
        props.setSelectedGrowthScenarioId(selectedGs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.growthScenarios]);

  const handleLayerChange =
    (
      layer:
        | "substations"
        | "charging-stations"
        | "poverty"
        | "non-white"
        | "mfh"
    ) =>
    () => {
      // if box is checked, layer is added to active-layers
      // if box is unchecked, layer is removed from active-layers
      if (activeLayers.includes(layer)) {
        setActiveLayers(activeLayers.filter((l) => l !== layer));
        if (markerType === layer) setPopupInfo(undefined);
      } else {
        setActiveLayers([...activeLayers, layer]);
      }
    };

  const colors: (string | number)[] = [
    "#FFF2FE",
    0.25,
    "#FFA3F1",
    0.5,
    "#FD63DC",
    0.75,
    "#AD369D",
    1,
    "#5C1657",
  ];

  const coverageLegend: (string | number)[] = [
    "Less than 25",
    "#FFF2FE",
    "25 to 50",
    "#FFA3F1",
    "50 to 75",
    "#FD63DC",
    "75 to 100",
    "#AD369D",
    "Greater than 100",
    "#5C1657",
  ];

  const chargingStationsLegend: string[] = [
    "Public",
    "#05C2cc",
    "Private",
    "#de6b48",
    "Exclusive",
    "#67d22d",
  ];

  const povertyLegend: (string | number)[] = [
    "25",
    "#0000ff",
    "50",
    "#0000ff",
    "75",
    "#0000ff",
    "100",
    "#0000ff",
  ];

  const nonWhiteLegend: (string | number)[] = [
    "25",
    "#ffeb7f",
    "50",
    "#ffeb7f",
    "75",
    "#ffeb7f",
    "100",
    "#ffeb7f",
  ];

  const mfhLegend: (string | number)[] = [
    "25",
    "#ff0000",
    "50",
    "#ff0000",
    "75",
    "#ff0000",
    "100",
    "#ff0000",
  ];

  const circleSizes: number[] = [3, 6, 9, 12];

  const coverageLayerStyle: LayerProps = {
    id: "charger-coverage",
    type: "fill",
    paint: {
      "fill-color": [
        "step",
        ["get", ["get", "geoid"], ["literal", coveragePercentage]],
        ...colors,
      ],
      "fill-opacity": 0.6,
    },
  };

  const blockGroupOutlineLayerStyle: LayerProps = {
    id: "block-groups-outline",
    type: "line",
    paint: {
      "line-color": "#000000",
      "line-width": 1,
    },
  };

  const substationsLayerStyle: LayerProps = {
    id: "substations",
    type: "circle",
    paint: {
      "circle-color": "#458f28",
      "circle-radius": 5,
    },
  };

  const chargingStationsLayerStyle: LayerProps = {
    id: "charging-stations",
    type: "circle",
    paint: {
      "circle-color": [
        "case",
        ["in", ["get", "evNetwork"], ["literal", EXCLUSIVE_EV_NETWORKS]],
        "#67d22d",
        ["==", ["get", "access"], "public"],
        "#05C2cc",
        ["==", ["get", "access"], "private"],
        "#de6b48",
        "#c0c0c0",
      ],
      "circle-radius": 5,
    },
  };

  const povertyLayerStyle: LayerProps = {
    id: "poverty",
    type: "circle",
    paint: {
      "circle-color": "#0000ff",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const nonWhiteLayerStyle: LayerProps = {
    id: "non-white",
    type: "circle",
    paint: {
      "circle-color": "#ffeb7f",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const mfhLayerStyle: LayerProps = {
    id: "mfh",
    type: "circle",
    paint: {
      "circle-color": "#ff0000",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const demographicsGeoJson: any = useMemo(() => {
    if (props.demographics !== undefined) {
      let features = [];
      for (const [geoid, demographic] of Object.entries(props.demographics)) {
        const blockGroup = props.blockGroups?.features.find(
          (feature) => feature.properties?.geoid === geoid
        );
        if (blockGroup) {
          const centroid = blockGroup.properties?.centroid;
          const feature = {
            type: "Feature",
            properties: {
              geoid,
              demographic,
            },
            geometry: {
              type: "Point",
              coordinates: centroid.coordinates,
            },
          };
          features.push(feature);
        }
      }
      return {
        type: "FeatureCollection",
        features,
      };
    }
    return undefined;
  }, [props.demographics, props.blockGroups]);

  function getBlockGroupPopupContent(
    blockGroup: any,
    scaledDemand: any,
    existingChargerCoverage: any,
    currentView: string
  ) {
    const geoid = blockGroup.properties.geoid;
    const area = blockGroup.properties.area;
    const demandkWh = scaledDemand
      ? scaledDemand[blockGroup.properties.geoid] ?? 0
      : 0; // if demand undefined, set 0
    const existingChargerCoveragekWh = existingChargerCoverage
      ? existingChargerCoverage[blockGroup.properties.geoid] ?? 0
      : 0; // if props.existingChargerCoverage undefined, set 0
    return (
      <BlockGroupPopupContent
        geoid={geoid}
        area={area}
        demand={demandkWh}
        existingChargerCoverage={existingChargerCoveragekWh}
        currentView={currentView}
      />
    );
  }

  function getPopupContent(popupInfo: PopupInfo) {
    switch (markerType) {
      case "charger-coverage":
        return getBlockGroupPopupContent(
          popupInfo.feature,
          scaledDemand,
          coveragePercentage,
          "coverage"
        );
      case "substations":
        return getSubstationPopupContent(popupInfo.feature);
      case "charging-stations":
        return <ChargingStationPopupContent feature={popupInfo.feature} />;
      case "poverty":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "non-white":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "mfh":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "block-groups-outline":
        return (
          <>
            <Typography align="center">
              This is a block group boundary.
            </Typography>
            <Typography align="center">
              Click on a block group or marker to view more information.
            </Typography>
          </>
        );
      default:
        return (
          <GenericGeoJsonFeaturePopupContent feature={popupInfo.feature} />
        );
    }
  }

  function getSubstationPopupContent(substation: any) {
    return (
      <SubstationPopupContent
        name={substation.properties.name}
        status={substation.properties.status}
        lines={substation.properties.num_lines}
        maxVoltage={substation.properties.max_voltage}
        minVoltage={substation.properties.min_voltage}
        maxInfer={substation.properties.max_infer}
        minInfer={substation.properties.min_infer}
      />
    );
  }

  return (
    <Stack direction={"row"} sx={{ height: "100%" }}>
      <Stack sx={{ width: "462px", height: "100%" }}>
        <Stack
          spacing={2}
          sx={{ padding: "30px", overflowY: "auto", height: "100%" }}
        >
          <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography variant="controlTitle">Charger Coverage</Typography>
              <Button
                onClick={() => {
                  setSteps(tourSteps);
                  setCurrentStep(0);
                  setIsOpen(true);
                }}
                color="info"
                startIcon={<HelpOutlineIcon />}
                variant={"outlined"}
                size={"small"}
              >
                Tutorial
              </Button>
            </Stack>
            <AccessControl permission={"read:block_group_popups"}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"flex-end"}
                spacing={1}
              >
                <InfoIcon color="info" fontSize="small" />
                <Typography>
                  Click a block group to see detailed information
                </Typography>
              </Stack>
            </AccessControl>
          </Box>
          <Divider orientation="horizontal" flexItem />
          <Stack spacing={1} className="vehicle-population-controls">
            <HelpTooltip
              title={
                "A growth scenario and year, as specified in the Timeline tab, determine the vehicle population "
              }
            >
              <Typography variant="h3">Vehicle Population Controls</Typography>
            </HelpTooltip>
            <GrowthScenarioControl
              selectedGrowthScenario={
                filteredGrowthScenarios?.find(
                  (scenario) => scenario.id === props.selectedGrowthScenario?.id
                ) || undefined
              }
              growthScenarios={filteredGrowthScenarios}
              onChange={props.onGrowthScenarioChange}
            />
            {lightDutyVehicleClass === undefined && (
              <Alert severity="warning">
                <AlertTitle>Incompatible Growth Scenario</AlertTitle>
                The selected growth scenario does not have light-duty demand
                data. Please select a different growth scenario.
              </Alert>
            )}
            <YearControl
              value={props.year}
              years={range(2020, 2051)}
              onChange={props.onYearChange}
            />
            <EvPenetration
              numEvs={currentYearVehicleClassData?.numEvs}
              percent={currentYearVehicleClassData?.evFractionOfAllVehicles}
            />
          </Stack>
          <Divider orientation="horizontal" flexItem />
          <Stack spacing={1}>
            <Typography variant="h3">Demand Controls</Typography>
            <SeasonControl
              ac={props.ac}
              onChange={props.onAcChange}
              chargingDemandSimulations={props.chargingDemandSimulations}
            />
            <Divider orientation="horizontal" flexItem />
          </Stack>
          <Box className={"charger-controls"}>
            <Stack spacing={1}>
              <HelpTooltip
                title={
                  "Select which chargers contribute to the charger coverage calculation"
                }
              >
                <Typography variant="h3">Charger Coverage Controls</Typography>
              </HelpTooltip>
              <ChargerLevelControl
                selectedChargerLevels={selectedChargerLevels}
                onChange={handleSelectedChargerLevelsChange}
              />
              <ChargerAccessControl
                includePrivateChargers={includePrivateChargers}
                onIncludePrivateChargersChange={
                  handleIncludePrivateChargersChange
                }
                includeExclusiveNetworks={includeExclusiveNetworks}
                onIncludeExclusiveNetworksChange={
                  handleIncludeExclusiveNetworksChange
                }
              />
              <AccessControl permission="read:coverage_utilization_control">
                <UtilizationControl
                  utilization={utilization}
                  onChange={handleUtilizationChange}
                />
              </AccessControl>
            </Stack>
          </Box>
          <Divider orientation="horizontal" flexItem />
          <Stack spacing={1} className={"layer-controls"}>
            <Typography variant="h3">Layer Controls</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("substations")}
                  onChange={handleLayerChange("substations")}
                  name="block-groups"
                />
              }
              label="Substations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("charging-stations")}
                  onChange={handleLayerChange("charging-stations")}
                  name="block-groups"
                />
              }
              label="Charging Stations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("poverty")}
                  onChange={handleLayerChange("poverty")}
                  name="block-groups"
                />
              }
              label="Poverty"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeLayers.includes("non-white")}
                  onChange={handleLayerChange("non-white")}
                  name="block-groups"
                />
              }
              label="Non-White"
            />
            {!isTxPPC && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeLayers.includes("mfh")}
                    onChange={handleLayerChange("mfh")}
                    name="block-groups"
                  />
                }
                label="Multifamily Housing"
              />
            )}
          </Stack>
        </Stack>
        <StickyBottomBox>
          <DownloadButton
            onClick={downloadChargingStationInfo}
            error={errorDownloading}
            loading={downloading}
          />
        </StickyBottomBox>
      </Stack>
      <Box sx={{ height: "100%", flex: 1, position: "relative" }}>
        <Backdrop
          sx={{
            color: "#FFFFFF",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={coverageLoading}
        >
          <Stack alignItems={"center"} spacing={2}>
            <CircularProgress color="inherit" />
            <Collapse in={loadingDemand}>
              <Typography variant="h3">Loading coverage...</Typography>
            </Collapse>
            <Collapse in={props.loading}>
              <Typography variant="h3">Loading data...</Typography>
            </Collapse>
          </Stack>
        </Backdrop>
        <Stack
          spacing={2}
          sx={{
            position: "absolute",
            margin: "8px",
            zIndex: "1000",
            top: 0,
            right: 0,
            height: `calc(100% - 20px - 8px * 2)`,
            pointerEvents: "none",
          }}
          alignItems={"flex-end"}
          direction={"column"}
        >
          <Box
            sx={{
              borderRadius: "10px",
              backgroundColor:
                displayMode === "dark"
                  ? "hsla(0,0%,100%,.8)"
                  : "hsla(0,0%,0%,.8)",
              pointerEvents: "auto",
              paddingRight: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={displayMode === "dark"}
                  onChange={handleDisplayModeChange}
                />
              }
              label="Dark Mode"
              labelPlacement="start"
              componentsProps={{
                typography: {
                  color: displayMode === "dark" ? "black" : "white",
                },
              }}
            />
          </Box>
        </Stack>
        <WidgetContainer>
          <Widget sx={{ width: "270px" }}>
            <Legend
              title={"Existing Charger Coverage"}
              colors={coverageLegend}
              units={"%"}
              className={"coverage-legend"}
            />
          </Widget>
          {activeLayers.includes("charging-stations") && (
            <Widget sx={{ width: "210px" }}>
              <Legend
                title={"Charging Stations"}
                colors={chargingStationsLegend}
                units={""}
                className={"charging-station-legend"}
              />
            </Widget>
          )}
          {activeLayers.includes("poverty") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Poverty"}
                colors={povertyLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
          {activeLayers.includes("non-white") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Non-White"}
                colors={nonWhiteLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
          {activeLayers.includes("mfh") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Multifamily Housing"}
                colors={mfhLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
        </WidgetContainer>
        <Map
          id="map"
          {...viewport}
          ref={mapRef}
          onMove={(evt) => setViewport(evt.viewState)}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          cursor={cursor}
          mapStyle={
            displayMode === "dark"
              ? "mapbox://styles/mapbox/dark-v11"
              : "mapbox://styles/mapbox/light-v11"
          }
          interactiveLayerIds={[
            coverageLayerStyle.id || "",
            blockGroupOutlineLayerStyle.id || "",
            substationsLayerStyle.id || "",
            chargingStationsLayerStyle.id || "",
            povertyLayerStyle.id || "",
            nonWhiteLayerStyle.id || "",
            mfhLayerStyle.id || "",
          ]}
        >
          <GeocoderControl
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN ?? ""}
            position="top-left"
          />
          <NavigationControl position={"top-left"} />
          <HomeButton
            // when the location changes, we need to render a new HomeButton
            // because otherwise the listeners do not get updated with the new
            // value of location. The HomeButton's onRemove() will remove the
            // previous element from the map
            key={props.location?.name}
            onClick={handleRecenterClick}
          />
          {activeLayers.includes("charger-coverage") && (
            <Source
              id="charger-coverage"
              type="geojson"
              data={props.blockGroups}
            >
              <Layer beforeId="waterway-label" {...coverageLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("block-groups-outline") && (
            <Source
              id="block-groups-outline"
              type="geojson"
              data={props.blockGroups}
            >
              <Layer
                beforeId="waterway-label"
                {...blockGroupOutlineLayerStyle}
              />
            </Source>
          )}
          {activeLayers.includes("substations") && (
            <Source id="substations" type="geojson" data={props.substations}>
              <Layer beforeId="waterway-label" {...substationsLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("charging-stations") && (
            <Source
              id="charging-stations"
              type="geojson"
              data={props.chargingStations}
              cluster={true}
              clusterMinPoints={5}
              clusterMaxZoom={16} // Max zoom to cluster points on
              clusterRadius={50} // Radius of each cluster when clustering points (defaults to 50)
            >
              <Layer
                id="clusters"
                type="circle"
                source="charging-stations"
                filter={["has", "point_count"]}
                paint={{
                  "circle-color": "#691c63",
                  "circle-radius": 18,
                  "circle-stroke-width": 5,
                  "circle-stroke-color": "#691c63",
                  "circle-stroke-opacity": 0.3,
                }}
              />
              <Layer
                id="cluster-count"
                type="symbol"
                source="charging-stations"
                filter={["has", "point_count"]}
                layout={{
                  "text-field": "{point_count}",
                  "text-size": 10,
                }}
                paint={{
                  "text-color": "#ffffff",
                }}
              />
              <Layer
                beforeId="waterway-label"
                {...chargingStationsLayerStyle}
                filter={["!", ["has", "point_count"]]}
              />
            </Source>
          )}
          {activeLayers.includes("poverty") && (
            <Source id="poverty" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...povertyLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("non-white") && (
            <Source id="non-white" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...nonWhiteLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("mfh") && (
            <Source id="mfh" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...mfhLayerStyle} />
            </Source>
          )}
          {popupInfo && (
            <Popup
              style={{ zIndex: 1100 }}
              anchor={anchor}
              longitude={popupInfo.lng}
              latitude={popupInfo.lat}
              onClose={() => setPopupInfo(undefined)}
              maxWidth={"none"}
              closeButton={false}
            >
              {getPopupContent(popupInfo)}
            </Popup>
          )}
        </Map>
      </Box>
    </Stack>
  );
}

export default CoveragePage;
