import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { FleetMixVehicle, VehicleType } from "../fleet-mix";

export type FleetMixVehicleTypeConfigurationProps = {
  vehicle: FleetMixVehicle;
  vehicleTypeOptions: VehicleType[];
  onRemove: () => void;
  onChange?: (vehicle: FleetMixVehicle) => void;
};

export function FleetMixVehicleTypeConfiguration({
  vehicle,
  vehicleTypeOptions,
  onRemove,
  onChange,
}: FleetMixVehicleTypeConfigurationProps) {
  return (
    <>
      <Stack spacing={2} direction="row" alignItems="center">
        <IconButton onClick={onRemove}>
          <RemoveCircleOutlineIcon color="error" />
        </IconButton>
        <FormControl sx={{ width: "25%" }}>
          <InputLabel id="light-duty-label" size="small">
            Vehicle Type
          </InputLabel>
          <Select
            defaultValue=""
            value={vehicle.type}
            labelId="light-duty-label"
            label="Vehicle Type"
            size="small"
            error={vehicle.typeError}
            onChange={(e) => {
              if (onChange !== undefined) {
                vehicle.typeError = false;
                vehicle.errorMessage = "";
                vehicle.type = e.target.value as VehicleType;
                onChange(vehicle);
              }
            }}
          >
            {vehicleTypeOptions.map((vehicleType) => (
              <MenuItem value={vehicleType}>{vehicleType}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={"Number Electric"}
          type="number"
          inputProps={{ min: 0 }}
          error={vehicle.numEvError}
          value={vehicle.numEv}
          sx={{ width: "22%" }}
          size="small"
          onChange={(e) => {
            if (onChange !== undefined) {
              vehicle.numEvError = false;
              vehicle.numIceError = false;
              vehicle.errorMessage = "";
              vehicle.numEv = Number(e.target.value.replaceAll(".", ""));
              onChange(vehicle);
            }
          }}
        />
        <TextField
          label={"Number ICE"}
          type="number"
          inputProps={{ min: 0 }}
          error={vehicle.numIceError}
          value={vehicle.numIce}
          sx={{ width: "22%" }}
          size="small"
          onChange={(e) => {
            if (onChange !== undefined) {
              vehicle.numIceError = false;
              vehicle.numIceError = false;
              vehicle.errorMessage = "";
              vehicle.numIce = Number(e.target.value.replaceAll(".", ""));
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: -1 }}
          label={"Total Annual Mileage"}
          value={vehicle.annualMileage}
          error={vehicle.annualMileageError}
          sx={{ width: "31%" }}
          size="small"
          onChange={(e) => {
            if (onChange !== undefined) {
              vehicle.annualMileageError = false;
              vehicle.errorMessage = "";
              vehicle.annualMileage = Number(e.target.value);
              onChange(vehicle);
            }
          }}
        />
      </Stack>
      {vehicle.errorMessage && (
        <Stack>
          <Typography
            sx={{ marginLeft: 1, marginTop: -1 }}
            variant="caption"
            style={{ color: "#d32f2f" }}
          >
            {vehicle.errorMessage}
          </Typography>
        </Stack>
      )}
    </>
  );
}
