export type FleetMix = {
  lightDutyVehicles: FleetMixVehicle[];
  mediumDutyVehicles: FleetMixVehicle[];
  heavyDutyVehicles: FleetMixVehicle[];
};

export type FleetMixVehicle = {
  id: string;
  type?: VehicleType | string;
  numEv: number;
  numIce: number;
  annualMileage?: number;
  typeError: boolean;
  numEvError: boolean;
  numIceError: boolean;
  annualMileageError: boolean;
  errorMessage: string;
};

export type VehicleType =
  | "Sedan"
  | "SUV"
  | "Pickup"
  | "Van"
  | "Minivan"
  | "150 mile Range Box Truck"
  | "200 mile Range Box Truck"
  | "230 mile Range Box Truck"
  | "300 mile Range Tractor Trailer"
  | "500 mile Range Tractor Trailer"
  | "250 mile Range Tractor Trailer";

export const lightDutyVehicleTypes: VehicleType[] = [
  "Sedan",
  "SUV",
  "Pickup",
  "Van",
  "Minivan",
];

export const mediumDutyVehicleTypes: VehicleType[] = [
  "150 mile Range Box Truck",
  "200 mile Range Box Truck",
  "230 mile Range Box Truck",
];

export const heavyDutyVehicleTypes: VehicleType[] = [
  "300 mile Range Tractor Trailer",
  "500 mile Range Tractor Trailer",
  "250 mile Range Tractor Trailer",
];

interface RiskAssessment {
  assessment: string;
  recommend: string;
}

export const riskAssessment: { [key: string]: RiskAssessment } = {
  HIGH: {
    assessment:
      "Transitioning to EV at this time may bring implementation complexity and/or high costs.",
    recommend:
      "Downsizing electrification plans to specific routes or vehicles that have a higher potential ROI or simpler operational complexity.",
  },
  LOW: {
    assessment:
      "You can realize the advantages of fleet electrification cost effectively with relatively few challenges.",
    recommend:
      "Contact your utility about participation in their fleet electrification incentive programs.",
  },
  MEDIUM: {
    assessment:
      "You may want to re-assess portions of your plan to optimize your EV potential.",
    recommend:
      "Obtaining a detailed electrification optimization plan from MicroGrid Labs.",
  },
};
